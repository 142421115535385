import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {facebookLinkAccountRequest} from '../../core/_requests'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import toast from 'react-hot-toast'
import { useState } from 'react'

export const FacebookLinkAccount = ({updateProfile}) => {
    const [loadingFB, setLoadingFB] = useState(false)
    async function responseFacebook (response: any){
        setLoadingFB(true)
        try {
          const {data: auth} = await facebookLinkAccountRequest(response.userID, response.name, response.email);
          if(auth.message){
            toast.success(auth.message);
            setLoadingFB(false);
            updateProfile();
          }
        } catch (error: any) {
            toast.error(error.response.data.message)
            setLoadingFB(false)
        }
    }
    return (
        <FacebookLogin
          appId="771121446768214"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook} 
          icon={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
          textButton="Link account with Facebook"
          render={renderProps => (
            <button onClick={renderProps.onClick} className="btn btn-flex btn-light btn-lg w-55 mb-5">
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                className='h-20px me-3'
              />
              {!loadingFB && <span className='indicator-label'>Link account with Facebook</span>}
              {loadingFB && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              </button>
          )}
          />
    )
}