import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import React, { useEffect, useReducer, useState } from 'react'
import axios from 'axios'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import toast from 'react-hot-toast';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PropagateLoader } from 'react-spinners'

const API_URL = process.env.REACT_APP_API_URL

export const TERAPII_API_URL = `${API_URL}/terapii/get`
export const TERAPII_TOGGLE_API_URL = `${API_URL}/terapii/toggle/`
export const TERAPII_STERGERE_API_URL = `${API_URL}/terapii/stergere/`
export const TERAPII_LIKE_API_URL = `${API_URL}/terapii/like/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Terapii',
        path: '/terapii',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

let kineto_status: any;

function TerapiiView(){
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState<any[]>([])
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const is_admin = currentUser?.admin
    useEffect(() => {
        axios({
            method: 'get',
            url: TERAPII_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            kineto_status = response.data.kineto;
            setData(response.data.data)
            setTimeout(function() {
                setIsLoading(false)
            }, 1500);
        });
    }, [reducerValue])
    const ToggleTerapie = async (id: any, action: any) => {
        axios({
            method: 'POST',
            url: TERAPII_TOGGLE_API_URL + id + '/' + action,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            forceUpdate()
            toast.success(response.data.message);
        });
    }
    const StergeTerapie = async (id: any) => {
        axios({
            method: 'POST',
            url: TERAPII_STERGERE_API_URL + id,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            forceUpdate()
            toast.success(response.data.message);
        });
    }
    const LikeTerapie = async (id: any) => {
        axios({
            method: 'POST',
            url: TERAPII_LIKE_API_URL,
            data: {'id_terapie' : id},
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            forceUpdate()
            toast.success(response.data.message);
        });
    }
    return (
        <React.Fragment>
            {isLoading &&
            <div className="d-flex justify-content-center">
                <PropagateLoader loading={true} color="#36d7b7" />
            </div>
            }
            {isLoading &&
            <br />
            }
            <TransitionGroup className={'row'}>
                {data.map((item, index) => {
                return (
                    <CSSTransition key={index} classNames={{
                        enterActive: 'animate__animated animate__fadeIn'
                    }} timeout={360}>
                        <div className="col-md-4 col-sm-6 feature-item rounded py-3">
                            <div className={item.status ? 'card card-flush h-lg-100': 'card card-flush h-lg-100 bg-light-warning'}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50px me-5">
                                            <img src={toAbsoluteUrl(item.avatar)}></img>
                                        </div>
                                        <div className="d-flex flex-column mr-auto">
                                            <div className="d-flex flex-column mr-auto">
                                                <a className="text-dark text-hover-primary font-size-h4 font-weight-bolder mb-1">{item.titlul}</a>
                                                <span className="text-muted font-weight-bold">Terapie creată de <Link to={"/profile/" + item.sqlid}><b>{item.username}</b></Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-10 mt-5 font-weight-bold" dangerouslySetInnerHTML={{__html: item.descriere}}></div>
                                </div>
                                <div className="card-footer d-flex flex-center">
                                    <div className="d-flex">
                                        <a onClick={() => LikeTerapie(item.id)} className={item.is_user_liked ? 'btn btn-sm btn-light btn-light-danger px-4 py-2' : 'btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'}>
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            {item.likes}
                                        </a>
                                    </div>
                                </div>
                                { is_admin &&
                                <div className="card-footer d-flex flex-center flex-wrap pt-0">
                                    <div className="d-flex flex-center">
                                        { item.status == 0 &&
                                        <div className="d-flex align-items-center mr-7">
                                            <a onClick={() => ToggleTerapie(item.id, 1)} className="btn btn-light-success btn-sm my-1 me-2">Acceptă</a>
                                        </div>
                                        }
                                        { item.status == 1 &&
                                        <div className="d-flex align-items-center mr-7">
                                            <a onClick={() => ToggleTerapie(item.id, 0)} className="btn btn-light-danger btn-sm my-1 me-2">Respinge</a>
                                        </div>
                                        }
                                        <div className="d-flex align-items-center mr-7">
                                            <a onClick={() => StergeTerapie(item.id)} className="btn btn-light-danger btn-sm my-1 me-2">Șterge</a>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </CSSTransition>
                    );
                })}
            </TransitionGroup>
        </React.Fragment>
    )
}

function AdaugaTerapieComponent(){
    const {currentUser} = useAuth()
    const is_admin = currentUser?.admin
    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className="card-title">
                    <span className="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/>
                        <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/>
                        <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/>
                        <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/>
                        </svg>
                    </span>
                    <div className="alert-text"><b>Adaugă o terapie pentru a fi vizibilă tuturor</b></div>
                </div>
                {/* kineto_status == 'Kinetoterapeut' && de adaugat cand mergem pe live */}
                { is_admin &&
                <div className="card-toolbar">
                    <div className="d-flex justify-content-end" data-kt-subscription-table-toolbar="base">
                        <Link to={'/terapii/adauga'} className='btn btn-success btn-shadow-hover font-weight-bold mr-2'>
                            Adaugă terapie
                        </Link>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

const TerapiiWrapper = () => {
const intl = useIntl()
return (
    <>
    <PageTitle breadcrumbs={breadcrumps}>Terapii</PageTitle>
    <AdaugaTerapieComponent />
    <br></br>
    <TerapiiView />
    </>
)
}

export {TerapiiWrapper}