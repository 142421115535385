import axios from "axios"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useAuth } from "../../../modules/auth"
import { Avatar } from "./Avatar"
import { UploadAvatar } from "./UploadAvatar"

const API_URL = process.env.REACT_APP_API_URL

// export const FACEBOOK_AVATAR_API_URL = `${API_URL}/facebook_avatar/`
export const USER_AVATARS_API_URL = `${API_URL}/get_user_avatars/`

interface AvatarData {
  name: string;
  social: number;
  avatar_url: string | null;
  using: number;
}

interface Avatars {
  uploaded: AvatarData;
  default: AvatarData;
  facebook: AvatarData;
  google: AvatarData;
}

const Avatars = (props) => {
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const [userAvatars, setUserAvatars] = useState<Avatars>({
      uploaded: { name: 'Uploaded', social: 1, avatar_url: null, using: null },
      default: { name: 'Default', social: 0, avatar_url: null, using: null },
      facebook: { name: 'Facebook', social: 2, avatar_url: null, using: null },
      google: { name: 'Google', social: 3, avatar_url: null, using: null },
    });

    const GetUserAvatars = () => {
        axios({
            method: 'GET',
            url: USER_AVATARS_API_URL,
            headers: {'Authorization': `${user_token}`},
        })
        .then(function (r) {
          setUserAvatars(prevAvatars => ({
            uploaded: {...prevAvatars.uploaded, avatar_url: r.data.avatars.s3, using: r.data.avatars.using, get_avatars: {GetUserAvatars}, update_profile: props},
            default: {...prevAvatars.default, avatar_url: r.data.avatars.default, using: r.data.avatars.using, get_avatars: {GetUserAvatars}, update_profile: props},
            facebook: {...prevAvatars.facebook, avatar_url: r.data.avatars.facebook, using: r.data.avatars.using, get_avatars: {GetUserAvatars}, update_profile: props},
            google: {...prevAvatars.google, avatar_url: r.data.avatars.google, using: r.data.avatars.using, get_avatars: {GetUserAvatars}, update_profile: props},
          }));
        });
    }

    useEffect(() => {
        GetUserAvatars()
    }, [props]);
    return (
        <div className="card mb-5 mb-xl-10 tab-pane animate__animated animate__fadeIn" id="avatars_view_container" role="tabpanel">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Avatars</h3>
                </div>
                <div className="d-flex my-4">
                    
                </div>
            </div>
            <div className="card-body p-9">
              <UploadAvatar get_avatars={GetUserAvatars} update_profile={props}/>
              {Object.entries(userAvatars).map(([key, avatar]) => {
                if (avatar.avatar_url) {
                  return <Avatar key={key} props={avatar} />;
                }
                return null;
              })}
            </div>
        </div>
    )
}

export {Avatars}