import axios from 'axios'
import { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { GlobalNotifications } from '../../../_metronic/partials/widgets/dashboard/GlobalNotifications'
import { KinetoInfo } from '../../../_metronic/partials/widgets/dashboard/KinetoInfo'
import { UltimeleActualizari } from '../../../_metronic/partials/widgets/dashboard/UltimeleActualizari'
import { UsersOnline } from '../../../_metronic/partials/widgets/dashboard/UsersOnline'
import { useAuth } from '../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL

export const DASHBOARD_GET_API_URL = `${API_URL}/update_dashboard/`

const breadcrumps: Array<PageLink> = [
    {
        title: '',
        path: '',
        isSeparator: false,
        isActive: false,
    },
]

function DashboardFirstRow(){
    const website_bazat_pe_svg = toAbsoluteUrl('/media/svg/humans/custom-4.svg')
    return (
        <div className="row g-5 g-xl-8 animate__animated animate__fadeIn">
            <div className="col-xl-6 ">
                <KinetoInfo />
            </div>
            <div className="col-xl-3">
                <UltimeleActualizari />
            </div>
            <div className="col-xl-3">
                <div className="card card-flush h-lg-100">
                    <div className="card-body d-flex p-0 card-rounded">
                        <div className="flex-grow-1 p-10 card-rounded flex-grow-1 bgi-no-repeat" style={{backgroundColor: `#663259`, backgroundPosition: `calc(100% + 0.5rem)`, backgroundSize: `auto 75%`, backgroundImage: `url("${website_bazat_pe_svg}")`}}>
                            <h4 className="text-inverse-danger mt-2 font-weight-bolder">Website Bazat pe</h4>
                            <div className="mt-5">
                                <div className="d-flex mb-5">
                                    <span className="svg-icon svg-icon-md svg-icon-white flex-shrink-0 mr-3">
                                        
                                    </span>
                                    <span className="text-white">Mișcare</span>
                                </div>
                                <div className="d-flex mb-5">
                                    <span className="svg-icon svg-icon-md svg-icon-white flex-shrink-0 mr-3">
                
                                    </span>
                                    <span className="text-white">Oameni</span>
                                </div>
                                <div className="d-flex">
                                    <span className="svg-icon svg-icon-md svg-icon-white flex-shrink-0 mr-3">
                                        
                                    </span>
                                    <span className="text-white">Terapie</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

type PropsSecondsRow = {
    bgImage?: string
  }
const DashboardSecondRow = ({
    bgImage = toAbsoluteUrl('/media/svg/shapes/abstract-4.svg'),
  }: PropsSecondsRow) => {
    const [stiriKineto, setStiriKineto] = useState<null | any>(null)
    const [stiriAltele, setStiriAltele] = useState<null | any>(null)
    const [OnlinesNum, setOnlinesNum] = useState<null | any>(null)
    const [Guests, setGuests] = useState<null | any>(null)
    const [Onlines, setOnlines] = useState<null | any>(null)
    const [globalNotifications, setGlobalNotification] = useState([])
    const [lastUser, setLastUser] = useState<null | any>(null);
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const is_admin = currentUser?.admin

    useEffect(() => {
        axios({
            method: 'GET',
            url: DASHBOARD_GET_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            let data = response.data.data;
            setStiriKineto(data.data_stiri_kineto)
            setStiriAltele(data.data_stiri_altele)
            setLastUser(data.last_user)
            setGlobalNotification(data.global_notifications)
            setOnlinesNum(data.onlines_num)
            setGuests(data.guests)
            setOnlines(data.onlines)
        });
    }, [])
    return (
        <div>
            <div className="row g-5 g-xl-8">
                { stiriKineto &&
                <div className="col-xl-4">
                    <div className="card bgi-no-repeat card-xl-stretch mb-xl-8 card-flush h-lg-100" style={{backgroundPosition: `right top`, backgroundSize: `30% auto`, backgroundImage: `url("${bgImage}")`}}>
                        <div className="card-body">
                            <a href="#" className="card-title fw-bolder text-muted text-hover-primary fs-4">Despre Kinetoterapie</a>
                            <br></br>
                            <br></br>
                            <Link to={'/profile/' + stiriKineto?.sqlid} className='card-title fw-bolder text-muted text-hover-primary fs-4'>
                                Creat de: {stiriKineto?.username}
                            </Link>
                            <div className="fw-bolder text-primary my-6">{stiriKineto?.date}</div>
                            <Link to={'/forum/' + stiriKineto?.id} className='fw-bolder text-gray-800 fs-4 mb-2 text-hover-primary'>
                                {stiriKineto?.titlul}
                            </Link>
                            <span className="font-weight-bold text-muted font-size-lg" dangerouslySetInnerHTML={{__html: stiriKineto?.text}}></span>
                        </div>
                    </div>
                </div>
                }
                { stiriAltele &&
                <div ng-if="data.data_stiri_altele" className="col-xl-4">
                    <div className="card bgi-no-repeat card-xl-stretch mb-xl-8 card-flush h-lg-100" style={{backgroundPosition: `right top`, backgroundSize: `30% auto`, backgroundImage: `url("${bgImage}")`}}>
                        <div className="card-body">
                            <a href="#" className="card-title fw-bolder text-muted text-hover-primary fs-4">Despre Altele</a>
                            <br></br>
                            <br></br>
                            <Link to={'/profile/' + stiriAltele?.sqlid} className='card-title fw-bolder text-muted text-hover-primary fs-4'>
                                {stiriAltele?.username}
                            </Link>
                            <div className="fw-bolder text-primary my-6">{stiriAltele?.date}</div>
                            <Link to={'/forum/' + stiriAltele?.id} className='fw-bolder text-gray-800 fs-4 mb-2 text-hover-primary'>
                                {stiriAltele?.titlul}
                            </Link>
                            <span className="font-weight-bold text-muted font-size-lg" dangerouslySetInnerHTML={{__html: stiriAltele?.text}}></span>
                        </div>
                    </div>
                </div>
                }
                { lastUser &&
                <div className="col-xl-4">
                    <div className="card card-xl-stretch mb-5 mb-xl-8 card-flush h-lg-100">
                        <div className="card-body d-flex align-items-center pt-13 pb-0">
                            <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                <Link to={'/profile/' + lastUser?.id} className='fw-bolder text-gray-800 fs-4 mb-2 text-hover-primary'>
                                    {lastUser?.username}
                                </Link>
                                <span className="fw-bold text-muted fs-5">Bine ai venit!</span>
                            </div>
                            <img src="/media/avatars/004-boy-1.svg" alt="" className="align-end h-100px"></img>
                        </div>
                    </div>
                </div>
                }
            </div>
            { is_admin &&
            <div className="row g-5 g-xl-8 my-1">
                <div className='col-xxl-4'>
                    <UsersOnline
                        className='card-xl-stretch mb-xl-8'
                        chartColor='danger'
                        chartHeight='200px'
                        strokeColor='#cb1e46'
                        countUsers={OnlinesNum}
                        guests={Guests}
                        onlines={Onlines}
                    />
                </div>
                <GlobalNotifications items={globalNotifications} />
            </div>
            }
        </div>
    )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={breadcrumps}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardFirstRow />
      <br></br>
      <DashboardSecondRow />
    </>
  )
}

export {DashboardWrapper}
