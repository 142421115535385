import axios from "axios"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { useAuth } from "../../../modules/auth"
import RemoveForum from "../Components/RemoveForum";

const API_URL = process.env.REACT_APP_API_URL

export const KINETO_STIRI_API_URL = `${API_URL}/forum/kinetoterapie/`

async function FetchKineto () {
    const res = await axios.get(KINETO_STIRI_API_URL);
    return res.data;
}
async function getFetch() {
    const response = await FetchKineto();
    return response.data
}

const KinetoterapieStiri = (props) => {
    const { isError, isSuccess, isLoading, data, error } = useQuery(
        ["kineto_stiri"],
        getFetch,
        { staleTime: 3000 }
    );
    const {currentUser} = useAuth()
    const is_admin = currentUser?.admin
    const user_token = currentUser?.token
    if(props.view == 'kinetoterapie'){
        return (
            <div className="card card-xl-stretch mb-5 mb-xl-8 animate__animated animate__fadeIn" id="kinetoterapie_stire_container" role="tabpanel">
                <div className="card-header align-items-center border-0 mt-3">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bolder text-dark fs-3">Știri privind Kinetoterapia</span>
                    </h3>
                </div>
                <div className="card-body pt-5">
                    {isLoading &&
                    <div className="d-flex justify-content-center">
                        <PropagateLoader loading={isLoading} color="#36d7b7" />
                    </div>
                    }
                    {data?.map((item, index) => {
                    return (
                    <div key={index} className="d-flex mb-7">
                        <div className="symbol symbol-50px me-5">
                            <img src={item.avatar} className="" alt="" />
                        </div>
                        <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                <Link to={'/forum/' + item.id} className="fs-5 text-gray-800 text-hover-primary fw-bolder">{item.titlul}</Link>
                                <Link to={'/forum/' + item.id} className="text-gray-400 fw-bold fs-7 my-1">Citește mai mult</Link>
                                <span className="text-gray-400 fw-bold fs-7">By:
                                <Link to={'/profile/' + item.sqlid} className="text-primary fw-bold">{item.username}</Link></span>
                            </div>
                            <div className="text-end py-lg-0 py-2">
                                {is_admin &&
                                <a type="button" className="badge badge-danger" onClick={() => RemoveForum(item.id, user_token)}>Remove</a>
                                }
                                <span className="text-gray-800 fw-boldest fs-3">{item.views}</span>
                                <span className="text-gray-400 fs-7 fw-bold d-block">Views</span>
                            </div>
                        </div>
                    </div>
                    );
                    })}
                </div>
            </div>
        )
    }
}

export {KinetoterapieStiri}