import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useAuth } from "../../modules/auth";
import { useJsApiLoader, GoogleMap, Autocomplete, MarkerF } from '@react-google-maps/api'
import toast from "react-hot-toast";
import { DateTimePicker, DateTimePickerSelectedType, DateTimePickerSelectorType } from 'react-datetime-pickers';
import 'react-datetime-pickers/dist/index.css';
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL
const GOOGLE_MAP_KEY = process.env.GOOGLE_MAP_KEY

export const PROGRAMARE_GET_API_URL = `${API_URL}/programare_update/`
export const PROGRAMARE_ADD_API_URL = `${API_URL}/programare/adaugare/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Kinetoterapeuti',
        path: '/kinetoterapeuti/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ProgramareView = () => {
    const navigate = useNavigate();
    // for datetimepicker
    setTimeout(function(){ document.getElementsByClassName("react-datetime-pickers-input")[0].classList.add("form-control") }, 1000);
    const [date, setDate] = useState<DateTimePickerSelectedType>();
    const [selector, setSelector] = useState<DateTimePickerSelectorType>(DateTimePickerSelectorType.DAY);
    const handleDateChange = (date?: DateTimePickerSelectedType) => {
        setDate(date);
    };
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAhDjeTg2bDGMcUsVG6bCukMKHtw95kaQs',
        libraries: ['places'],
    })
    const [autocomplete, setAutocomplete] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState({lat: 44.4355117, lng: 26.10174169999999, name: null, address: null})
    const { user_id } = useParams();
    const [kinetoterapeut, setKinetoterapeut] = useState('')
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const [inputs, setInputs] = useState({phone_number: '', descriere: ''});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    useEffect(() => {
        axios({
            method: 'GET',
            url: PROGRAMARE_GET_API_URL + user_id,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (r) {
            setKinetoterapeut(r.data.kinetoterapeut)
        });
    }, [user_id])
    const SubmitForm = () => { 
        if(!inputs.descriere || !inputs.phone_number || !selectedLocation.lat || !selectedLocation.lng || !date){
            return toast.error('Completeaza toate campurile!')
        }
        const date_selected = new Date(Date.parse(date.toString()))
        axios({
            method: 'POST',
            url: PROGRAMARE_ADD_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {MapLat: selectedLocation.lat, MapLng: selectedLocation.lng, descriere: inputs.descriere, 
                numar_telefon: inputs.phone_number, kineto_sqlid: user_id, address: selectedLocation.address, 
                place: selectedLocation.name, programat: moment(date_selected).format("YYYY-MM-DD HH:mm")}
        })
        .then(function (r) {
            if(r.data.error){
                return toast.error(r.data.message);
            }
            toast.success(r.data.message);
            navigate('/programari/')
        });
    }
    if (!isLoaded) {
        return <React.Fragment />
    }
    const onLoad = (autocompleteLoad) => (
        setAutocomplete(autocompleteLoad)
    )
    const onPlaceChanged = () => {
        setSelectedLocation({lat: autocomplete.getPlace().geometry.location.lat(), lng: autocomplete.getPlace().geometry.location.lng(),
                            name: autocomplete.getPlace().name, address: autocomplete.getPlace().formatted_address})
    }
    return (
        <React.Fragment>
            <div className="card card-custom animate__animated animate__fadeIn">
                <div className="card-header">
                    <h3 className="card-title">
                    Completează câmpurile de mai jos
                    </h3>
                </div>
                <div className="card-body" id="kineto_id">
                <div className="form-group">
                    <div className="alert alert-info d-flex align-items-center p-5 mb-10">
                        <span className="svg-icon svg-icon-2hx svg-icon-info me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                            </svg>
                        </span>
                        <div className="d-flex flex-column">
                            <h4 className="mb-1 text-info">Ai ales sa te programezi la <b>{kinetoterapeut}</b>!</h4>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-form-label text-right col-lg-3 col-sm-12">Număr de telefon</label>
                    <div className="col-lg-4 col-md-9 col-sm-12">
                        <input name="phone_number" className="form-control" placeholder="" maxLength={10} type="number" value={inputs.phone_number} onChange={handleChange}/>
                        <br />
                        <span className="form-text text-muted">Adaugă un număr de telefon pentru a fi contactat ulterior</span>
                    </div>
                </div>
                <br />
                <br />
                <div className="form-group row">
                    <label className="col-form-label text-right col-lg-3 col-sm-12">Locația</label>
                    <div className="col-lg-4 col-md-9 col-sm-12">
                        <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                            <input id="pac-input" className="form-control" type="text" placeholder="Search location" />
                        </Autocomplete>
                        <br />
                        <span className="form-text text-muted">Unde se va desfășura activitatea</span>
                        <br />
                        <div style={{width: '800px', height: '400px'}}>
                            { isLoaded &&
                            <GoogleMap
                                center={{lat: selectedLocation.lat, lng: selectedLocation.lng}}
                                zoom={12}
                                mapContainerStyle={{ width: '100%', height: '100%' }}
                                options={{
                                    zoomControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                }}
                                >
                                <MarkerF position={{lat: selectedLocation.lat, lng: selectedLocation.lng}} title={selectedLocation?.name}/>
                            </GoogleMap>
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="form-group row">
                    <label className="col-form-label text-right col-lg-3 col-sm-12">Data programării</label>
                    <div className="col-lg-4 col-md-9 col-sm-12">
                    <DateTimePicker
                        selected={date}
                        selector={selector}
                        timePicker={true}
                        onChange={handleDateChange}
                        timeStep={600}
                    />
                        <br />
                        <span className="form-text text-muted">Alege data și ora programării</span>
                    </div>
                </div>
                <br />
                <br />
                <div className="form-group row">
                    <label className="col-form-label text-right col-lg-3 col-sm-12">Descriere</label>
                    <div className="col-lg-4 col-md-9 col-sm-12">
                        <textarea className="form-control" name="descriere" maxLength={3000} placeholder="" rows={6} value={inputs.descriere} onChange={handleChange}></textarea>
                        <br />
                        <span className="form-text text-muted">Cu ce poate <b>{kinetoterapeut}</b> să te ajute</span>
                    </div>
                </div>
                <br />
                <br />
                <div className="card-footer">
                    <div className="row">
                        <div className="col-lg-9 ml-lg-auto">
                            <button onClick={SubmitForm} className="btn btn-primary mr-2">Submit</button>
                            <button type="reset" className="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <PageTitle breadcrumbs={breadcrumps}>{'Programeaza-te la ' + kinetoterapeut}</PageTitle>
        </React.Fragment>
    )
}
  
export {ProgramareView}