/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'

function GlobalNotifications (items : any) {
    let arr_items = items.items;
    return(
        <div className="col-xl-4">
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bolder mb-2 text-dark">Activities</span>
                    </h3>
                    <div className="card-toolbar">

                    </div>
                </div>
                <div className="card-body pt-5 card-scroll h-200px">
                    {arr_items.map((item : any) => {
                        return (
                        <div key={item.id} className="d-flex align-items-center mb-8">
                            <img className="me-2" width="35" height="35" src={item.icon}/>
                            <div className="flex-grow-1">
                                <Link to={item.link} className='card-title fw-bolder text-muted text-hover-primary fs-4' dangerouslySetInnerHTML={{__html: item.text}}></Link>
                                <span className="text-muted fw-bold d-block">{item.date}</span>
                            </div>
                            <span className="badge badge-light-success fs-8 fw-bolder">New</span>
                        </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export {GlobalNotifications}
