import axios from "axios"
import React, { useEffect, useReducer, useState } from "react"
import toast from "react-hot-toast"
import { useIntl } from "react-intl"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth"
import {loadStripe} from '@stripe/stripe-js';

const API_URL = process.env.REACT_APP_API_URL

export const PROMOVAT_STATUS_API_URL = `${API_URL}/kinetoterapeuti/promovare/get/`
export const PROMOVAT_SET_ADMIN_API_URL = `${API_URL}/kinetoterapeuti/promovare/set/`
export const PROMOVARE_PLATESTE_API_URL = `${API_URL}/api/kinetoterapeuti/promovare/plateste/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Kinetoterapeuti',
        path: '/kinetoterapeuti',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const PromovareView = () => {
    const [promovat, setPromovat] = useState(false)
    const [myProfile, setMyProfile] = useState(false)
    const [loading, setLoading] = useState(true)
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const is_admin = currentUser?.admin
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    useEffect(() => {
        axios({
            method: 'GET',
            url: PROMOVAT_STATUS_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            setPromovat(response.data.promovat_status)
            setMyProfile(response.data.my_profile)
            setLoading(false)
        });
    }, [reducerValue])
    const Promoveaza = () => {
        axios({
            method: 'POST',
            url: PROMOVAT_SET_ADMIN_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            forceUpdate()
            toast.success(response.data.message)
        });
    }
    const Plateste = async () => {
        const current_hostname = window.location.hostname;
        axios({
            method: 'POST',
            url: PROMOVARE_PLATESTE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {current_hostname: current_hostname}
        })
        .then(async function (response) {
            const stripePromise = await loadStripe(response.data.checkout_public_key);
            stripePromise?.redirectToCheckout({
                sessionId: response.data.checkout_session_id
            })
        });
    }
    return (
        <React.Fragment>
            <div className="card animate__animated animate__fadeIn" id="kt_pricing">
                <div className="card-body p-lg-17">
                    <div className="d-flex flex-column">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bolder mb-5">Promovează-ți profilul pentru o mai bună așezare în listă</h1>
                            <div className="text-gray-400 fw-bold fs-5">Mai jos poti sa observi opțiunile prezente</div>
                        </div>
                        <div className="row g-10">
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-dark mb-5 fw-boldest">3 luni acces</h1>
                                            <div className="text-center">
                                                <span className="fs-3x fw-bolder text-primary">25 de LEI</span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Badge cu textul de promovat</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Profilul tău va fi afișat printre primele</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Notificări în timp real prin sms și mail la noile programări</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <a href="#" className="btn btn-sm btn-danger">Indisponibil</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-20 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-dark mb-5 fw-boldest">O lună acces</h1>
                                            <div className="text-center">
                                                <span className="fs-3x fw-bolder text-primary">5 LEI</span>
                                                <span className="fs-7 fw-bold opacity-50">/
                                                <span data-kt-element="period">Mon</span></span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Badge cu textul de promovat</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Profilul tău va fi afișat printre primele</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1">Notificări în timp real prin mail la noile programări</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-400 flex-grow-1">Notificări în timp real prin sms la noile programări</span>
                                                <span className="svg-icon svg-icon-danger svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM18 12C18 11.4 17.6 11 17 11H7C6.4 11 6 11.4 6 12C6 12.6 6.4 13 7 13H17C17.6 13 18 12.6 18 12Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        {loading &&
                                         <button className="btn btn-primary text-uppercase font-weight-bolder px-15 py-3">
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                         </button>
                                        }
                                        {!myProfile && !loading && 
                                            <button className="btn btn-danger text-uppercase font-weight-bolder px-15 py-3">Nu ai profil creat</button>
                                        }
                                        {promovat && !loading &&
                                            <button className="btn btn-warning text-uppercase font-weight-bolder px-15 py-3">Activat</button>
                                        }
                                        {!promovat && is_admin && myProfile && !loading &&
                                            <button onClick={() => Promoveaza()} className="btn btn-success text-uppercase font-weight-bolder px-15 py-3">Activează</button>
                                        }
                                        {!promovat && !is_admin && myProfile && !loading &&
                                            <button onClick={Plateste} className="btn btn-success text-uppercase font-weight-bolder px-15 py-3">Plătește</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-dark mb-5 fw-boldest">6 luni acces</h1>
                                            <div className="text-center">
                                                <span className="fs-3x fw-bolder text-primary">45 de LEI</span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Badge cu textul de promovat</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Profilul tău va fi afișat printre primele</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Notificări în timp real prin sms și mail la noile programări</span>
                                                <span className="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="currentColor"/>
                                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <a href="#" className="btn btn-sm btn-danger">Indisponibil</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const PromovareKinetoWrapper = () => {
    const intl = useIntl()
    return (
      <>
        <PageTitle breadcrumbs={breadcrumps}>{'Promovare profil Kinetoterapeut'}</PageTitle>
        <PromovareView />
      </>
    )
}
  
export {PromovareKinetoWrapper}