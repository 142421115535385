import React, { useEffect, useState } from "react"
import { useAuth } from "../../../modules/auth"
import { FacebookLinkAccount } from "../../../modules/auth/components/facebook/FacebookLinkAccount"
import { FacebookUnLinkAccount } from "../../../modules/auth/components/facebook/FacebookUnLinkAccount"
import { GoogleLinkAccount } from "../../../modules/auth/components/google/GoogleLinkAccount"
import { GoogleUnLinkAccount } from "../../../modules/auth/components/google/GoogleUnLinkAccount"
import { ChangeEmail, ChangeLocation, ChangeNumber } from "../request/ProfileSettingsChange"
import { SocialEnum } from "../../../modules/auth/components/requests/Social"

const API_URL = process.env.REACT_APP_API_URL

export const UNLINK_SOCIAL_API_URL = `${API_URL}/unlink_social/`

const ProfileSettings = (props) => {
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const user = props.data
    const [input, setInput] = useState({new_number: '', new_oras: '', new_email: ''})
    const [socialFacebook, setSocialFacebook] = useState(false)
    const [socialGoogle, setSocialGoogle] = useState(false)

    const SetSocials = (socials) => {
        setSocialFacebook(false);
        setSocialGoogle(false);
        socials.forEach((social) => {
            if (social.social === SocialEnum.Facebook) {
              setSocialFacebook(true);
            } else if (social.social === SocialEnum.Google) {
              setSocialGoogle(true);
            }
        });
    }

    useEffect(() => {
        if(props.socials){
            SetSocials(props.socials)
        }
    }, [props]);
    return (
        <div className="card mb-5 mb-xl-10 tab-pane animate__animated animate__fadeIn" id="settings_view_container" role="tabpanel">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Settings Overview</h3>
                </div>
                <div className="d-flex my-4">
                    
                </div>
            </div>
            <div className="card-body p-9">
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Registered Using</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                {user.RegisteredWith === SocialEnum.Google &&
                                <span className="badge badge-light-primary fw-bold fs-6">
                                    <img alt="Logo" src="/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />
                                    Google
                                </span>
                                }
                                {user.RegisteredWith === SocialEnum.Facebook &&
                                <span className="badge badge-light-primary fw-bold fs-6">
                                    <img alt="Logo" src="/media/svg/brand-logos/facebook-4.svg" className="h-20px me-3" />
                                    Facebook
                                </span>
                                }
                                {user.RegisteredWith === 1 &&
                                <span className="badge badge-light-primary fw-bold fs-6">Website method</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Social</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                {socialFacebook ? (
                                    <FacebookUnLinkAccount updateProfile={props.UpdateProfile} />
                                    ) : (
                                    <FacebookLinkAccount updateProfile={props.UpdateProfile} />
                                )}
                            </div>
                            <div className="col-lg-12 fv-row fv-plugins-icon-container my-4">
                                {socialGoogle ? (
                                    <GoogleUnLinkAccount updateProfile={props.UpdateProfile} />
                                    ) : (
                                    <GoogleLinkAccount updateProfile={props.UpdateProfile} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Display Name</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                <input type="text" name="fname" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder={user.username} disabled />
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        </div>
                    </div>
                </div>
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Număr de telefon</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type={'number'} value={input?.new_number} onChange={(e) => setInput({ ...input, new_number: e.target.value })} name="new_number" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder={user.Phone} />
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                    <button onClick={() => ChangeNumber(user_token, input.new_number)} className="btn btn-sm btn-light btn-active-light-success me-3">Change</button>
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Oraș</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input value={input?.new_oras} onChange={(e) => setInput({ ...input, new_oras: e.target.value })} type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder={user.Location} />
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                            <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                <button onClick={() => ChangeLocation(user_token, input.new_oras)} className="btn btn-sm btn-light btn-active-light-success me-3">Change</button>
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        </div>
                    </div>
                </div>
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Email address</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input value={input?.new_email} onChange={(e) => setInput({ ...input, new_email: e.target.value })} className="form-control form-control-solid" placeholder={user.email} />
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                <button onClick={() => ChangeEmail(user_token, input.new_email)} className="btn btn-sm btn-light btn-active-light-success me-3">Change</button>
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        </div>
                    </div>
                </div>
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Delete Your Account</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                <button ng-click="delete_account();" className="btn btn-sm btn-danger me-3">Delete</button>
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {ProfileSettings}