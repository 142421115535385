import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useAuth } from "../../modules/auth";

const API_URL = process.env.REACT_APP_API_URL

export const KINETO_SAVE_URL = `${API_URL}/kinetoterapeuti/adaugare/submit/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Kinetoterapeuti',
        path: '/kinetoterapeuti/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

interface KinetoFormDataAdaugaState {
    contact: number;
    facultatea_absolvita: string;
    anul_absolvirii: number;
    descriere: string;
    orasul: string;
}

const AdaugaKinetoPage = () => {
    const [formData, setFormData] = useState<KinetoFormDataAdaugaState>({contact: 40, facultatea_absolvita: '', anul_absolvirii: 2020, descriere: '', orasul: ''})
    const navigate = useNavigate();
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    function handleSubmit(){
        if(!formData.contact || !formData.facultatea_absolvita || !formData.anul_absolvirii || !formData.descriere || !formData.orasul){
            return toast.error('Completeaza toate campurile!');
        }
        axios({
            method: 'POST',
            url: KINETO_SAVE_URL,
            data: {contact: formData.contact, facultatea_absolvita: formData.facultatea_absolvita, 
                            anul_absolvirii: formData.anul_absolvirii,
                            descriere: formData.descriere, orasul: formData.orasul},
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            // aici trebuie redirect
            navigate('/kinetoterapeuti/');
            toast.success(response.data.message);
        });
    }
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    return (
        <div className="card card-flush">
            <div className="card-header pt-8">
                <div className="card-title">
                    <h2>Completează câmpurile de mai jos</h2>
                </div>
            </div>
            <div className="card-body">
                <div className="alert alert-info d-flex align-items-center p-5 mb-10">
                    <span className="svg-icon svg-icon-2hx svg-icon-info me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                        </svg>
                    </span>
                    <div className="d-flex flex-column">
                        <h4 className="mb-1 text-info">Aceste date vor fi folosite pentru a genera un profil unic de kinetoterapeut</h4>
                    </div>
                </div>
                <form className="form">
                    <div className="form-group row">
                        <label className="col-form-label text-right col-lg-3 col-sm-12">Număr de telefon</label>
                        <div className="col-lg-4 col-md-9 col-sm-12">
                            <input className="form-control" maxLength={10} placeholder="" type="number" value={formData!.contact} onChange={handleChange} name="contact"/>
                            <span className="form-text text-muted">Adaugă un număr de telefon pentru a fi contactat ulterior</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label text-right col-lg-3 col-sm-12">Orașul</label>
                        <div className="col-lg-4 col-md-9 col-sm-12">
                            <input className="form-control" placeholder="" type="text" value={formData!.orasul} onChange={handleChange} name="orasul"/>
                            <span className="form-text text-muted">Orașul unde activezi</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label text-right col-lg-3 col-sm-12">Facultatea absolvită</label>
                        <div className="col-lg-4 col-md-9 col-sm-12">
                            <input className="form-control" placeholder="" type="text" value={formData!.facultatea_absolvita} onChange={handleChange} name="facultatea_absolvita"/>
                            <span className="form-text text-muted">Numele facultății absolvite</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label text-right col-lg-3 col-sm-12">Anul absolvirii facultătii</label>
                        <div className="col-lg-4 col-md-9 col-sm-12">
                            <input className="form-control" maxLength={4} placeholder="" type="number" value={formData!.anul_absolvirii} onChange={handleChange} name="anul_absolvirii"/>
                            <span className="form-text text-muted">De la sine înteles</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label text-right col-lg-3 col-sm-12">Descriere</label>
                        <div className="col-lg-4 col-md-9 col-sm-12">
                            <textarea className="form-control" maxLength={3000} placeholder="" rows={6} value={formData!.descriere} onChange={handleChange} name="descriere"></textarea>
                            <span className="form-text text-muted">Descrie serviciile tale în maximum 3000 de caractere</span>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row mt-12">
                        <div className="col-md-9 offset-md-3">
                            <button onClick={handleSubmit}  type="button" className="btn btn-primary">
                                <span className="indicator-label">Submit</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )     
}

const AdaugaKinetoWrapper = () => {
    const intl = useIntl()
    return (
      <>
        <PageTitle breadcrumbs={breadcrumps}>{'Adauga Profil Nou de Kinetoterapeut'}</PageTitle>
        <AdaugaKinetoPage />
      </>
    )
}
  
export {AdaugaKinetoWrapper}