import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {loadStripe} from '@stripe/stripe-js';
import { useAuth } from '../../modules/auth';
import axios from 'axios';
import DonatiiGet from './Requests/DonatiiGet';
import React from 'react';

const API_URL = process.env.REACT_APP_API_URL

export const DONEAZA_API_URL = `${API_URL}/doneaza/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

function DonatiiPageView(){
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const is_admin = currentUser?.admin
    const Doneaza = async (amount: number) => {
        const current_hostname = window.location.hostname;
        axios({
            method: 'POST',
            url: DONEAZA_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {current_hostname: current_hostname, amount: amount}
        })
        .then(async function (response) {
            const stripePromise = await loadStripe(response.data.checkout_public_key);
            stripePromise?.redirectToCheckout({
                sessionId: response.data.checkout_session_id
            })
        });
    }
    return (
        <React.Fragment>
            {is_admin &&
            <DonatiiGet />
            }
            <div className="card animate__animated animate__fadeIn" id="kt_pricing">
                <div className="card-body p-lg-17">
                    <div className="d-flex flex-column">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bolder mb-5">Contribuie la dezvoltarea acestui proiect</h1>
                        </div>
                        <div className="row g-10">
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-dark mb-5 fw-boldest">Donează această sumă</h1>
                                            <div className="text-center">
                                                <span className="fs-3x fw-bolder text-primary">10 LEI</span>
                                            </div>
                                        </div>
                                        <a onClick={() => Doneaza(10)} className="btn btn-sm btn-success">Donează</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-20 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-dark mb-5 fw-boldest">Donează această sumă</h1>
                                            <div className="text-center">
                                                <span className="fs-3x fw-bolder text-primary">50 LEI</span>
                                            </div>
                                        </div>
                                        <a onClick={() => Doneaza(50)} className="btn btn-sm btn-success">Donează</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-dark mb-5 fw-boldest">Donează această sumă</h1>
                                            <div className="text-center">
                                                <span className="fs-3x fw-bolder text-primary">100 de LEI</span>
                                            </div>
                                        </div>
                                        <a onClick={() => Doneaza(100)} className="btn btn-sm btn-success">Donează</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
  
const DonatiiWrapper = () => {
    return (
        <>
        <PageTitle breadcrumbs={breadcrumps}>{'Donatii'}</PageTitle>
        <DonatiiPageView />
        </>
    )
}
  
export {DonatiiWrapper}