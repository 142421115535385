import axios from "axios";
import React, { useEffect, useReducer, useState } from "react"
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from "react-quill";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";

const API_URL = process.env.REACT_APP_API_URL

export const KINETO_EDITEAZA_API_URL = `${API_URL}/kinetoterapeuti/editeaza/`
export const KINETO_EDITEAZA_POST_API_URL = `${API_URL}/kinetoterapeuti/editeaza/request/`
export const KINETO_STERGERE_API_URL = `${API_URL}/kinetoterapeuti/stergere/`
export const KINETO_EDITARE_DESCRIERE_API_URL = `${API_URL}/kinetoterapeuti/editare/descriere/`

export const EditareKinetoView = () => {
    const [creator, setCreator] = useState<any>('')
    const [valueQuill, setValueQuill] = useState('');
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const {currentUser} = useAuth()
    const [inputs, setInputs] = useState({orasul: '', contact: '', facultatea: '', anul_absolvirii: ''})
    const user_token = currentUser?.token
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const is_logged = currentUser?.logged_in
    useEffect(() => {
        axios({
            method: 'GET',
            url: KINETO_EDITEAZA_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (r) {
            setCreator(r.data.creator_kineto)
            setValueQuill(r.data.creator_kineto.descriere);
        });
    }, [reducerValue])
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setInputs({ ...inputs, [name]: value });
    };
    const UpdateProfil = (tipul) => {
        let input_value = '';
        switch(tipul) {
          case 1:
            input_value = inputs.orasul;
            break;
          case 2:
            input_value = inputs.contact;
            break;
          case 3:
            input_value = inputs.facultatea;
            break;
          case 4:
            input_value = inputs.anul_absolvirii;
            break;
        }
        if(!input_value){return toast.error('Te rog sa completez câmpul înainte de a modifica.')}
        axios({
            method: 'POST',
            url: KINETO_EDITEAZA_POST_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {tipul: tipul, input_value: input_value}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    const UpdateDescriere = () => { 
        if(!valueQuill){return toast.error('Te rog sa completez câmpul înainte de a modifica.')}
        axios({
            method: 'POST',
            url: KINETO_EDITARE_DESCRIERE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {new_descriere: valueQuill}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    const handleSubmitModal = () => {
        axios({
            method: 'POST',
            url: KINETO_STERGERE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {sqlid: creator.id, kineto_sqlid: creator.sqlid}
        })
        .then(function (response) {
          if(response.data.message){
            toast.success(response.data.message);
            forceUpdate()
            setModalShow(false)
            navigate('/kinetoterapeuti/')
          }
        });
    }
    function handleCancelModal(){
        setModalShow(false);
    }
    const breadcrumps: Array<PageLink> = [
        {
            title: 'Kinetoterapeuti',
            path: '/kinetoterapeuti/',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Kinetoterapeuti',
            path: '/',
            isSeparator: true,
            isActive: true,
        },
      ]
    return (
        <React.Fragment>
        <div className="card animate__animated animate__fadeIn">
            <div className="card-header border-0 pt-6">
                <div className="card-title">
                    <span className="svg-icon svg-icon-success svg-icon-2x me-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M16.163 17.55C17.0515 16.6633 17.6785 15.5488 17.975 14.329C18.2389 13.1884 18.8119 12.1425 19.631 11.306L12.694 4.36902C11.8574 5.18796 10.8115 5.76088 9.67099 6.02502C8.15617 6.3947 6.81277 7.27001 5.86261 8.50635C4.91245 9.74268 4.41238 11.266 4.44501 12.825C4.46196 13.6211 4.31769 14.4125 4.0209 15.1515C3.72412 15.8905 3.28092 16.5617 2.71799 17.125L2.28699 17.556C2.10306 17.7402 1.99976 17.9897 1.99976 18.25C1.99976 18.5103 2.10306 18.7598 2.28699 18.944L5.06201 21.719C5.24614 21.9029 5.49575 22.0062 5.75601 22.0062C6.01627 22.0062 6.26588 21.9029 6.45001 21.719L6.88101 21.288C7.44427 20.725 8.11556 20.2819 8.85452 19.9851C9.59349 19.6883 10.3848 19.5441 11.181 19.561C12.1042 19.58 13.0217 19.4114 13.878 19.0658C14.7343 18.7201 15.5116 18.2046 16.163 17.55Z" fill="currentColor"/>
                        <path d="M19.631 11.306L12.694 4.36902L14.775 2.28699C14.9591 2.10306 15.2087 1.99976 15.469 1.99976C15.7293 1.99976 15.9789 2.10306 16.163 2.28699L21.713 7.83704C21.8969 8.02117 22.0002 8.27075 22.0002 8.53101C22.0002 8.79126 21.8969 9.04085 21.713 9.22498L19.631 11.306ZM13.041 10.959C12.6427 10.5604 12.1194 10.3112 11.5589 10.2532C10.9985 10.1952 10.4352 10.332 9.96375 10.6405C9.4923 10.949 9.14148 11.4105 8.97034 11.9473C8.79919 12.4841 8.81813 13.0635 9.02399 13.588L2.98099 19.631L4.36899 21.019L10.412 14.975C10.9364 15.1816 11.5161 15.2011 12.0533 15.0303C12.5904 14.8594 13.0523 14.5086 13.361 14.037C13.6697 13.5654 13.8065 13.0018 13.7482 12.4412C13.6899 11.8805 13.4401 11.357 13.041 10.959Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <div className="alert-text text-center"><b>{creator ? 'Modifică-ți profilul de kinetoterapeut cu usurință folosind funcțiile de mai jos.' : 'Din pacate nu am reusit sa gasim un profil de kinetoterapeut asociat acestui cont. Mai intai Adauga-ti contul in sectiunea de kinetoterapeuti!'}</b></div>
                </div>
            </div>
        </div>
        <br />
        <br />
        {creator &&
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-1 g-5 g-xl-9 animate__animated animate__fadeIn">
            <div className="col-md-4" id="creator_kineto" ng-if="creator_kineto">
                <div className="card card-flush h-lg-100">
                    {creator.promovat_status &&
                    <div className="ribbon ribbon-end ribbon-clip">
                        <div className="ribbon-label">
                            Promovat
                            <span className="ribbon-inner bg-success"></span>
                        </div>
                    </div>
                    }
                    {creator.status == 0 &&
                    <div ng-if="creator_kineto.status == 0" className="ribbon ribbon-start ribbon-clip">
                        <div className="ribbon-label">
                            În așteptare
                            <span className="ribbon-inner bg-warning"></span>
                        </div>
                    </div>
                    }
                    {creator.status == 1 &&
                    <div className="ribbon ribbon-start ribbon-clip">
                        <div className="ribbon-label">
                            Profil Activ
                            <span className="ribbon-inner bg-success"></span>
                        </div>
                    </div>
                    }
                    {creator.status == 2 &&
                    <div className="ribbon ribbon-start ribbon-clip">
                        <div className="ribbon-label">
                            Profil Respins
                            <span className="ribbon-inner bg-danger"></span>
                        </div>
                    </div>
                    }
                    <div className="card-body d-flex flex-center flex-column pt-12 p-9">
                        <div className="symbol symbol-65px symbol-circle mb-5">
                            <img src={creator.avatar} alt="image" />
                            <div className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                        </div>
                        <Link to={'/profile/' + creator.sqlid} className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{creator.username}</Link>
                        <span onClick={() => setModalShow(true)} className="btn btn-light-danger btn-sm my-1 me-2">Șterge</span>
                        {creator.promovat_status &&
                        <span className="btn btn-light-primary btn-sm my-1 me-2">Expire promovare: <b>{creator.promovat}</b></span>
                        }
                        <br />
                        <p className="mb-7" dangerouslySetInnerHTML={{__html: creator.descriere}}></p>
                        <div className="d-flex flex-column text-gray-600">
                            <div className="d-flex align-items-center py-2">
                                <div className="d-flex justify-content-between align-items-center my-1">
                                    <span className="bullet bg-primary me-3"></span>
                                    <span className="text-dark-75 font-weight-bolder mr-2">Editeaza descrierea:</span>
                                    <ReactQuill theme="snow" value={valueQuill} onChange={setValueQuill} />
                                    <button onClick={() => UpdateDescriere()} className="btn btn-success btn-sm" type="button">Modifică</button>
                                </div>
                            </div>
                            <br />
                            <div className="d-flex align-items-center py-2">
                                <div className="d-flex justify-content-between align-items-center my-1">
                                    <span className="bullet bg-primary me-3"></span>
                                    <span className="text-dark-75 font-weight-bolder mr-2">Orașul în care activez:</span>
                                    <input name="orasul" value={inputs!.orasul} onChange={handleChange} type="text" className="form-control me-2 " placeholder={creator.orasul} />
                                    <button onClick={() => UpdateProfil(1)} className="btn btn-success btn-sm" type="button">Modifică</button>
                                </div>
                            </div>
                            <br />
                            <div className="d-flex align-items-center py-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="bullet bg-primary me-3"></span>
                                    <span className="text-dark-75 font-weight-bolder mr-2">Contact:</span>
                                    &nbsp;
                                    &nbsp;
                                    <input name="contact" value={inputs!.contact} onChange={handleChange} type="number" maxLength={10} className="form-control me-2" placeholder={creator.contact} />
                                    <button onClick={() => UpdateProfil(2)} className="btn btn-success btn-sm" type="button">Modifică</button>
                                </div>
                            </div>
                            <br />
                            <div className="d-flex align-items-center py-2">
                                <div className="d-flex justify-content-between align-items-center my-1">
                                    <span className="bullet bg-primary me-3"></span>
                                    <span className="text-dark-75 font-weight-bolder mr-2">Facultatea absolvită:</span>
                                    &nbsp;
                                    &nbsp;
                                    <input name="facultatea" value={inputs!.facultatea} onChange={handleChange} type="text" className="form-control me-2" placeholder={creator.facultatea} />
                                    <button onClick={() => UpdateProfil(3)} className="btn btn-success btn-sm" type="button">Modifică</button>
                                </div>
                            </div>
                            <br />
                            <div className="d-flex align-items-center py-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="bullet bg-primary me-3"></span>
                                    <span className="text-dark-75 font-weight-bolder mr-2">Anul absolvirii:</span>
                                    &nbsp;
                                    &nbsp;
                                    <input name="anul_absolvirii" value={inputs!.anul_absolvirii} onChange={handleChange} type="text" className="form-control me-2" placeholder={creator.anul_absolvirii} />
                                    <button onClick={() => UpdateProfil(4)} className="btn btn-success btn-sm" type="button">Modifică</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        {creator && 
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={() => setModalShow(false)}
        >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Șterge profilul tau de kinetoterapeut
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <h3>Esti sigur ca vrei sa iti stergi profilul de kinetoterapeut ?</h3>
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={handleCancelModal} className="btn btn-light-dark btn-sm my-1 me-2">Close</Button>
        <Button onClick={() => handleSubmitModal()} className="btn btn-light-danger btn-sm my-1 me-2">Confirm</Button>
        </Modal.Footer>
        </Modal>
        }
        <PageTitle breadcrumbs={breadcrumps}>{'Editare profil kinetoterapeut ' + creator.username}</PageTitle>
        </React.Fragment>
    )
}