/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  bgImage?: string
}
const UltimeleActualizari = ({
  bgImage = toAbsoluteUrl('/media/svg/humans/custom-3.svg'),
}: Props) => {
  return (
    <div className="card card-flush h-lg-100">
        <div className="card-body d-flex p-0">
            <div className="flex-grow-1 bg-danger p-8 card-rounded flex-grow-1 bgi-no-repeat" style={{backgroundPosition: `calc(100% + 0.5rem) bottom`, backgroundSize: `auto 70%`, backgroundImage: `url("${bgImage}")`}}>
                <h4 className="text-inverse-danger mt-2 font-weight-bolder">Ultimele actualizări ale platformei</h4>
                <p className="text-inverse-danger my-6">Vezi ultimele noutăți
                apărute pe platformă</p>
                <a href="/actualizari/" className="btn btn-warning font-weight-bold py-2 px-6">Du-mă</a>
            </div>
        </div>
    </div>
  )
}

export {UltimeleActualizari}
