import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const GET_MESSAGES_API_URL = `${API_URL}/private_messages_update/`
    
const FetchPrivateMessages = async (token) => {
    const url = GET_MESSAGES_API_URL
    const { data } = await axios.get(url, {headers: {'Authorization' : token}})
    return data
}
    
export default FetchPrivateMessages;