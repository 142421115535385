import React, { useState } from "react"
import { EditDescription } from "../Components/EditDescription"

const ProfileOverview = (props) => {
    const [showModal, setShowModal] = useState(false)
    let profile_about = '' 
    if(props.profile_about < 1){
        profile_about = 'Descriere nesetata!'
    } else {
        profile_about = props.profile_about
    }
    const toggleModal = () => {
        setShowModal(!showModal)
    }
    return (
        <React.Fragment>
        <div className="card mb-5 mb-xl-10 tab-pane active show animate__animated animate__fadeIn" id="profile_view_container" role="tabpanel">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Profile Overview</h3>
                </div>
                <div className="d-flex my-4">
                    {props.isMyProfile && !props.profile_about && 
                        <a onClick={toggleModal} className="btn btn-sm btn-warning me-2 align-self-center">Adaugă descriere</a>
                    }
                    {props.isMyProfile && props.profile_about.length > 0 && 
                        <a onClick={toggleModal} className="btn btn-sm btn-light me-3 align-self-center">Editează descrierea</a>
                    }
                </div>
            </div>
            <div className="card-body p-9">
                <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                    <div className="mb-5">
                        <div className="accordion-header py-3 d-flex" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_1">
                            <span className="accordion-icon">
                                <span className="svg-icon svg-icon-4">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1"></rect>
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                        </g>
                                    </svg>
                                </span>
                            </span>
                            <h3 className="fs-4 fw-bold mb-0 ms-4">Despre mine (<b>{props.username}</b>)</h3>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: profile_about}} id="kt_accordion_2_item_1" className="fs-6 collapse show ps-10" data-bs-parent="#kt_accordion_2">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EditDescription showModal={showModal} toggle={toggleModal} profile_about={props.profile_about} ProfileAbout={props.ProfileAbout}/>
        </React.Fragment>
    )
}

export {ProfileOverview}