import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers/components/KTSVG"
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useAuth } from "../../modules/auth";
import FetchPrivateMessages from "./Components/FetchPrivateMessages";
import { ViewMessage } from "./Partials/ViewMessage";

export const GetFetch = async (token) => {
    const r = await FetchPrivateMessages(token);
    return r.data
}

const breadcrumps: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '/',
        isSeparator: true,
        isActive: true,
    },
  ]

export const PrivateMessagesView = () => {
    const {id_message} = useParams();
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const { isSuccess, data } = useQuery(
        ["list_messages"],
        () => GetFetch(user_token),
        { staleTime: 3000 }
    );
    const [messageView, setMessageView] = useState(id_message)
    const GetMessage = (id_message) => {
        setMessageView(id_message)
    }
    return (
        <React.Fragment>
        {isSuccess &&
        <div className='d-flex flex-column flex-lg-row animate__animated animate__fadeIn'>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
                <div className='card card-flush'>
                    <div className='card-header pt-7' id='kt_chat_contacts_header'>
                        <form className='w-100 position-relative' autoComplete='off'>
                        <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                        />
            
                        <input
                            type='text'
                            className='form-control form-control-solid px-15'
                            name='search'
                            placeholder='Search by username or email...'
                        />
                        </form>
                    </div>
            
                    <div className='card-body pt-5' id='kt_chat_contacts_body'>
                        <div
                        className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                        data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                        data-kt-scroll-offset='0px'
                        >
                        {data.messages_received.length < 1 && data.messages_send.length < 1 &&
                        <div className="text-center"><b>Nu ai mesaje recente!</b></div>
                        }
                        {data.messages_received.map((item, index) => {
                        return (
                        <div key={index} onClick={() => GetMessage(item.id)} className='d-flex flex-stack py-4 bg-hover-light-success text-hover-success'>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px symbol-circle'>
                                <img src={item.senderAvatar} alt="image" />
                            </div>
            
                            <div className='ms-5'>
                                <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                {item.sendername}
                                </span>
                                <div className='fw-bold text-gray-400'>from {item.sendername} to {item.receivername}</div>
                            </div>
                            </div>
            
                            <div className='d-flex flex-column align-items-end ms-2'>
                            <span className='text-muted fs-7 mb-1'>{item.date}</span>
                            </div>
                        </div>
                        );
                        })}
                        <div className='separator separator-dashed d-none'></div>

                        {data.messages_send.map((item, index) => {
                        return (
                        <div key={index} onClick={() => GetMessage(item.id)} className='d-flex flex-stack py-4 bg-hover-light-success text-hover-success'>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px symbol-circle'>
                                <img src={item.senderAvatar} alt="image" />
                            </div>
            
                            <div className='ms-5'>
                                <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                {item.sendername}
                                </span>
                                <div className='fw-bold text-gray-400'>from {item.sendername} to {item.receivername}</div>
                            </div>
                            </div>
            
                            <div className='d-flex flex-column align-items-end ms-2'>
                            <span className='text-muted fs-7 mb-1'>{item.date}</span>
                            </div>
                        </div>
                        );
                        })}
                        <div className='separator separator-dashed d-none'></div>
                        </div>
                    </div>
                </div>
            </div>
            {messageView && 
            <ViewMessage messageView={messageView}/>
            }
        </div>
        }
        <PageTitle breadcrumbs={breadcrumps}>{'Mesajele mele private'}</PageTitle>
        </React.Fragment>
    )
}
