import axios from "axios";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_URL

export const CHANGE_NUMBER_API_URL = `${API_URL}/change_number/`
export const CHANGE_LOCATION_API_URL = `${API_URL}/change_location/`
export const CHANGE_EMAIL_API_URL = `${API_URL}/change_email/`

export const ChangeNumber = (user_token, new_number) =>{
    if(new_number.length != 10){
        return toast.error('Numarul de telefon trebuia sa contina 10 cifre incepand cu 0!')
    }
    axios({
        method: 'POST',
        url: CHANGE_NUMBER_API_URL,
        headers: {'Authorization': `${user_token}`},
        data: {new_number: new_number}
    })
    .then(function (r) {
        toast.success(r.data.message)
    });
}

export const ChangeLocation = (user_token, new_location) =>{
    if(!new_location){
        return toast.error('Te rugam sa completezi campul pentru a schimba orasul!') 
    }
    axios({
        method: 'POST',
        url: CHANGE_LOCATION_API_URL,
        headers: {'Authorization': `${user_token}`},
        data: {new_location: new_location}
    })
    .then(function (r) {
        toast.success(r.data.message)
    });
}

export const ChangeEmail = (user_token, new_email) =>{
    if(!new_email){
        return toast.error('Te rugam sa completezi campul pentru a schimba adresa de mail!') 
    }
    axios({
        method: 'POST',
        url: CHANGE_EMAIL_API_URL,
        headers: {'Authorization': `${user_token}`},
        data: {new_email: new_email}
    })
    .then(function (r) {
        if(r.data.error){
            return toast.error(r.data.message)
        }
        toast.success(r.data.message)
    });
}

export const DeleteAccount = () =>{
    
}