import axios from "axios";
import toast from "react-hot-toast"
import { useQuery } from "react-query";
import { useAuth } from "../../../modules/auth";

const API_URL = process.env.REACT_APP_API_URL

export const FORUM_DELETE_API_URL = `${API_URL}/forum/delete/`

function RemoveForum (id, user_token){
    axios({
        method: 'POST',
        url: FORUM_DELETE_API_URL,
        headers: {'Authorization': `${user_token}`},
        data: {id_stire: id}
    })
    .then(function (r) {
        toast.success(r.data.message)
    });
}

export default RemoveForum;