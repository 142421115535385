import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../../modules/auth";

const API_URL = process.env.REACT_APP_API_URL

export const GET_FRIENDS_API_URL = `${API_URL}/get_friends/`
export const TOGGLE_FRIEND_API_URL = `${API_URL}/profile/manage/`

const ProfileFriends = (props) => { 
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const user = props.data
    const [count_friends, setCount_Friends] = useState(0)
    const [sqlid_friends_dict, setSqlid_friends_dict] = useState<any[]>([])
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    let tab_friends = '';
    if(props.isMyProfile){
        tab_friends = 'My Friends'
    } else {
        tab_friends = user.username
    }
    useEffect(() => {
        axios({
            method: 'POST',
            url: GET_FRIENDS_API_URL,
            data: {sqlid: props.user_id},
            headers: {'Authorization': `${user_token}`},
        })
        .then(function (r) {
            setCount_Friends(r.data.count_friends)
            setSqlid_friends_dict(r.data.sqlid_friends_dict)
        });
    }, [reducerValue, user.id])   
    function RemoveFriend(sqlid: any){
        axios({
            method: 'POST',
            url: TOGGLE_FRIEND_API_URL + sqlid + '/1',
            headers: {'Authorization': `${user_token}`},
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    return (
        <div className="card mb-5 mb-xl-10 tab-pane animate__animated animate__fadeIn" id="friends_view_container" role="tabpanel">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{tab_friends} Overview (Total Friends: <b>{count_friends})</b></h3>
                </div>
                <div className="d-flex my-4">
                    
                </div>
            </div>
            <div className="card-body p-9">
                {!count_friends &&
                <div className="text-center">Nu am găsit prieteni!</div>
                }
            </div>
            {Object.keys(sqlid_friends_dict).map((item, i) => (
            <div key={i} className="card">
                <div className="card-body d-flex flex-center flex-column py-9 px-5">
                    <div className="symbol symbol-65px symbol-circle mb-5">
                        <img src={sqlid_friends_dict[item].avatar_friend} alt="image" />
                        <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-15px w-15px ms-n3 mt-n3"></div>
                    </div>
                    <a href={'/profile/' + sqlid_friends_dict[item].friend_sqlid} className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">{sqlid_friends_dict[item].friend_username}</a>
                    <div className="fw-semibold text-gray-400 mb-6">Friends since {sqlid_friends_dict[item].date_converted}</div>
                    {props.isMyProfile && 
                    <a onClick={() => RemoveFriend(sqlid_friends_dict[item].friend_sqlid)} href="#" className="btn btn-sm btn-light-danger" >
                    <span className="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                        </svg>
                    </span>
                    Unfriend</a>
                    }
                </div>
                <div className="separator my-10"></div>
            </div>
            ))}
        </div>
    )
}

export {ProfileFriends}