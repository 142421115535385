import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../../../modules/auth";

const API_URL = process.env.REACT_APP_API_URL

export const SET_PREFERRED_AVATAR_API_URL = `${API_URL}/set_preferred_avatar/`

const Avatar = ({props}) => {
  const {currentUser, setCurrentUser} = useAuth()
  const user_token = currentUser?.token

  const UpdateUserAvatar = (avatar) => {
    setCurrentUser({...currentUser, avatar_url: avatar});
  }
  
  const SetPreferredAvatar = (social) => {
    axios({
        method: 'POST',
        url: SET_PREFERRED_AVATAR_API_URL,
        headers: {'Authorization': `${user_token}`},
        data: {social_preferred: social}
    })
    .then(function (r) {
        props.get_avatars.GetUserAvatars();
        UpdateUserAvatar(r.data.avatar_updated)
        props.update_profile.UpdateProfile()
        toast.success(r.data.message);
    });
  } 

  return (
    <>
      <div className="row mb-16">
        <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar {props.name}</label>
        <div className="col-lg-8">
            <div className="row">
                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <div className="symbol symbol-65px me-5">
                        <img alt="select facebook" src={props.avatar_url} />
                    </div>
                </div>
                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                  {props.using == props.social &&
                    <label className="btn btn-sm btn-primary me-3">Using</label>
                  }
                  {props.using != props.social &&
                    <label onClick={() => SetPreferredAvatar(props.social)} className="btn btn-sm btn-light btn-active-light-success me-3">Use this</label>
                  }
                  <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export {Avatar}