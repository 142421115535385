import axios from "axios"
import { useEffect, useReducer, useState } from "react"
import toast from "react-hot-toast"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth"

const API_URL = process.env.REACT_APP_API_URL

export const NOTIFICATIONS_API_URL = `${API_URL}/notifications_update/`
export const DELETE_NOTIFICATIONS_API_URL = `${API_URL}/deletenotifications/`
export const READ_ALL_NOTIFICATIONS_API_URL = `${API_URL}/readallnotifications/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

function NotificationsView(){
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const [data, setData] = useState<any[]>([])
    const [rows, setRows] = useState(0)
    const [rowsCitite, setRowsCitite] = useState(0)
    const [rowsNecitite, setRowsNecitite] = useState(0)
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    useEffect(() => {
        axios({
            method: 'GET',
            url: NOTIFICATIONS_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (r) {
            setData(r.data.data)
            setRows(r.data.rows)
            setRowsCitite(r.data.rows_citite)
            setRowsNecitite(r.data.rows_necitite)
        });
    }, [reducerValue])
    function DeleteNotifications(){
        axios({
            method: 'GET',
            url: DELETE_NOTIFICATIONS_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    function ReadAllNotifications(){
        axios({
            method: 'GET',
            url: READ_ALL_NOTIFICATIONS_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    return(
        <div className="card mt-5 mt-xxl-0 animate__animated animate__fadeIn">
            <div className="card-header card-header-stretch">
                <div className="card-title d-flex align-items-center">
                <span className="svg-icon svg-icon-1 svg-icon-primary me-3 lh-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.25" d="M6 3C6 2.44772 6.44772 2 7 2C7.55228 2 8 2.44772 8 3V4H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H6V3Z" fill="#191213"></path>
                    <path d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14H17C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12H10ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H13C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16H7Z" fill="#121319"></path>
                    </svg>
                </span>
                <h3 className="fw-bolder m-0 text-gray-800">Notificările mele</h3>
                <div className="card-toolbar">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {rowsNecitite > 0 && 
                    <button onClick={() => ReadAllNotifications()} type="button" className="btn btn-sm btn-success">Marchează ca citite tot</button>
                    }
                    &nbsp;&nbsp;
                    <button onClick={() => DeleteNotifications()} type="button" className="btn btn-sm btn-danger">Șterge tot</button>
                </div>
                </div>
                <div className="card-toolbar m-0">
                <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder" role="tablist">
                    <li className="nav-item" role="presentation">
                    <a id="kt_activity_today_tab" className="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#kt_activity_today">Necitite</a>
                    </li>
                    <li className="nav-item" role="presentation">
                    <a id="kt_activity_week_tab" className="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_week">Citite</a>
                    </li>
                    <li className="nav-item" role="presentation">
                    <a id="kt_activity_month_tab" className="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_month">Toate</a>
                    </li>
                </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="tab-content">
                    <div id="kt_activity_today" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_activity_today_tab">
                        <div className="timeline">
                        {rowsNecitite < 1 &&
                        <h5 className="mb-5 text-center">Nu ai notificări necitite recente!</h5>
                        }
                        {data.filter((data) => 
                            data.readed == '0'
                        ).map((item, index) => {
                        return ( 
                        <div key={index} className="timeline-item">
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                            <div className={'symbol-label bg-' + item.badge}>
                                <img src={item.icon} alt="" />
                            </div>
                            </div>
                            <div className="timeline-content mb-10 mt-n2">
                            <div className="overflow-auto pe-3">
                                <Link to={item.href} id={item.id} className={item.class + ' text-dark me-2 fs-3'} dangerouslySetInnerHTML={{__html: item.info}}></Link>
                                <div className="d-flex align-items-center mt-1 fs-6">
                                <div className="text-muted me-2 fs-7">{item.date}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        );
                        })}
                    </div>
                </div>
                <div id="kt_activity_week" className="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_week_tab">
                    <div className="timeline">
                        {rowsCitite < 1 &&
                        <h5 className="mb-5 text-center">Nu ai notificări citite recente!</h5>
                        }
                        {data.filter((data) => 
                            data.readed == '1'
                        ).map((item, index) => {
                        return (
                        <div key={index} className="timeline-item">
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                            <div className={'symbol-label bg-' + item.badge}>
                                <img src={item.icon} alt="" />
                            </div>
                            </div>
                            <div className="timeline-content mb-10 mt-n2">
                            <div className="overflow-auto pe-3">
                                <Link to={item.href} id={item.id} className={item.class + ' text-dark me-2 fs-3'} dangerouslySetInnerHTML={{__html: item.info}}></Link>
                                <div className="d-flex align-items-center mt-1 fs-6">
                                <div className="text-muted me-2 fs-7">{item.date}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        );
                        })}
                    </div>
                </div>
                <div id="kt_activity_month" className="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_month_tab">
                    <div className="timeline">
                    {data.length < 1 &&
                    <h5 className="mb-5 text-center">Nu ai notificări recente!</h5>
                    }
                    {data.map((item, index) => {
                    return (
                    <div key={index} className="timeline-item">
                        <div className="timeline-line w-40px"></div>
                        <div className="timeline-icon symbol symbol-circle symbol-40px">
                        <div className={'symbol-label bg-' + item.badge}>
                            <img src={item.icon} alt="" />
                        </div>
                        </div>
                        <div className="timeline-content mb-10 mt-n2">
                        <div className="overflow-auto pe-3">
                            <Link to={item.href} id={item.id} className="[[item.class]] text-dark me-2 fs-3" dangerouslySetInnerHTML={{__html: item.info}}></Link>
                            <div className="d-flex align-items-center mt-1 fs-6">
                            <div className="text-muted me-2 fs-7">{item.date}</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    );
                    })}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
  const NotificationsWrapper = () => {
    const intl = useIntl()
    return (
      <>
        <PageTitle breadcrumbs={breadcrumps}>{'My Notifications'}</PageTitle>
        <NotificationsView />
      </>
    )
  }
  
export {NotificationsWrapper}