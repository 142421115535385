import CookieConsent from 'react-cookie-consent'
import toast from "react-hot-toast"

export const CookieConsentComponent = () => {
    const acceptedCookieConsent = () => {
        toast.success('You have accepted the Cookie Policy Consent!')
    }
    return (
        <CookieConsent
            onAccept={() => acceptedCookieConsent()}
            location="bottom"
            buttonText="I understand!"
            cookieName="CookiePolicyConsent"
            style={{ background: "#2B373B", textAlign: "center" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            This website uses cookies to enhance the user experience. Please confirm before using the website.{" "}
        </CookieConsent>
    )
}