import {FC} from 'react'
import {ToggleHelpDrawer} from './help-drawer/ToggleHelpDrawer'
import {HelpDrawer} from './help-drawer/HelpDrawer'
import { useAuth } from '../../../app/modules/auth'

const RightToolbar: FC = () => {
  const {currentUser} = useAuth()
  return (
    <>
      {/* <div className='engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-20 gap-2'>
        <ToggleHelpDrawer />
      </div> */}
      { currentUser?.logged_in &&
        <HelpDrawer />
      }
    </>
  )
}

export {RightToolbar}
