import axios from "axios";
import clsx from "clsx"
import React, { useEffect, useReducer, useState } from "react"
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAuth } from "../../../modules/auth";

const API_URL = process.env.REACT_APP_API_URL

export const VIEW_MESSAGE_API_URL = `${API_URL}/get_message/`
export const REPLY_MESSAGE_API_URL = `${API_URL}/private_messages/reply_message/`

export const ViewMessage = (props) => { 
    const id_message = props.messageView
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const user_id = currentUser?.id
    const [data, setData] = useState<any>()
    const [dataReplies, setDataReplies] = useState<any>([])
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const [animate, setAnimate] = useState('animate__animated animate__fadeIn')
    const [input, setInput] = useState('')
    useEffect(() => {
        axios({
            method: 'GET',
            url: VIEW_MESSAGE_API_URL + id_message,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            setAnimate('animate__animated animate__fadeIn')
            setData(response.data.data.data)
            setDataReplies(response.data.data.data_comments)
            setTimeout(function() {
                setAnimate('')
            }, 1000);
        });
    }, [reducerValue, props.messageView])
    const Reply = () => {
        if(input.length < 1){return toast.error('Te rugam sa completezi campul de trimitere mesaj!')}
        let receiver_id;
        if(data.receiver !== user_id){
            receiver_id = data.receiver
        }
        if(data.sender !== user_id){
            receiver_id = data.sender
        }
        axios({
            method: 'POST',
            url: REPLY_MESSAGE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {comment: input, id_message: data.id, receiver_id: receiver_id}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
            setInput('')
        });
    }
    return(
        <React.Fragment>
        {id_message > 0 && data && dataReplies &&
        <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
            <div className={'card ' + animate} id='kt_chat_messenger'>
                <div className='card-header' id='kt_chat_messenger_header'>
                    <div className='card-title'>
                    <div className='symbol-group symbol-hover'></div>
                    <div className='d-flex justify-content-center flex-column me-3'>
                        {data.title}

                        {/* <div className='mb-0 lh-1'>
                        <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                        <span className='fs-7 fw-bold text-gray-400'>Active</span>
                        </div> */}
                    </div>
                    </div>

                    {/* <div className='card-toolbar'>
                    <div className='me-n3'>
                        <button
                        className='btn btn-sm btn-icon btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        >
                        <i className='bi bi-three-dots fs-2'></i>
                        </button>
                        <Dropdown1 />
                    </div>
                    </div> */}
                </div>
                <div
                    className='card-body'
                    id='kt_chat_messenger_body'
                    >
                    <div
                        className={clsx('scroll-y me-n5 pe-5')}
                        data-kt-element='messages'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies={
                            '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                        }
                        data-kt-scroll-wrappers={
                            '#kt_content, #kt_chat_messenger_body'
                        }
                        data-kt-scroll-offset={'-2px'}
                    >
                    {/*  */}
                    <div className={data.sender === user_id ? 'd-flex justify-content-start mb-10' : 'd-flex justify-content-end mb-10'}>
                        <div className={data.sender === user_id ? 'd-flex flex-column align-items-start' : 'd-flex flex-column align-items-end'}>
                            <div className="d-flex align-items-center mb-2">
                                <div className="symbol  symbol-35px symbol-circle ">
                                    <img alt="Pic" src={data.avatar} />
                                    </div>
                                    <div className="ms-3">
                                        <Link to={'/profile/' + data.sender} className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{data.sendername}</Link>
                                        <span className="text-muted fs-7 mb-1">{data.date_converted}</span>
                                    </div>
                            </div>
                            <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text" dangerouslySetInnerHTML={{__html: data.content}}>

                            </div>
                            <span className="text-muted fs-7 mb-1">{data.readed ? 'Message read: ' + data.readed : 'Message not read'}</span>
                        </div>
                    </div>
                    {dataReplies.map((item, index) => {
                    return (
                    <div key={index} className={item.usersqlid === user_id ? 'd-flex justify-content-start mb-10' : 'd-flex justify-content-end mb-10'}>
                        <div className={item.usersqlid === user_id ? 'd-flex flex-column align-items-start' : 'd-flex flex-column align-items-end'}>
                            <div className="d-flex align-items-center mb-2">
                                <div className="symbol  symbol-35px symbol-circle ">
                                    <img alt="Pic" src={item.avatar} />
                                    </div>
                                    <div className="ms-3">
                                        <Link to={'/profile/' + item.usersqlid} className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{item.username}</Link>
                                        <span className="text-muted fs-7 mb-1">{item.date_converted}</span>
                                    </div>
                            </div>
                            <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text" dangerouslySetInnerHTML={{__html: item.comment}}>

                            </div>
                            <span className="text-muted fs-7 mb-1">{item.text_read}</span>
                        </div>
                    </div>
                    );
                    })}
                    </div>
                </div>
                <div
                    className='card-footer pt-4'
                    id='kt_chat_messenger_footer'
                >
                    <textarea
                    className='form-control form-control-flush mb-3'
                    rows={1}
                    data-kt-element='input'
                    placeholder='Type a message'
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    ></textarea>

                    <div className='d-flex flex-stack'>
                    <div className='d-flex align-items-center me-2'>
                        
                    </div>
                    <button
                        className='btn btn-primary'
                        type='button'
                        data-kt-element='send'
                        onClick={Reply}
                    >
                        Send
                    </button>
                    </div>
                </div>
            </div>
        </div>
        }
        </React.Fragment>
    )
}