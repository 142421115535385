import {Suspense} from 'react'
import { Toaster } from 'react-hot-toast'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { CookieConsentComponent } from './pages/Policy/components/CookieConsent'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Toaster
        toastOptions={{
          // icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
          duration: 5000
        }}
      />
      <CookieConsentComponent />

      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
