import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/api/login`
export const LOGIN_FACEBOOK_URL = `${API_URL}/api/login_with_facebook`
export const LINK_FACEBOOK_ACCOUNT_URL = `${API_URL}/api/link_with_facebook`
export const LOGIN_GOOGLE_URL = `${API_URL}/api/login_with_google`
export const LINK_GOOGLE_ACCOUNT_URL = `${API_URL}/api/link_with_google`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_account`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function loginFacebook(id_fb: number, username: string, token: string, email: string) {
  return axios.post<AuthModel>(LOGIN_FACEBOOK_URL, {
    id_fb,
    username,
    token,
    email
  })
}

export function facebookLinkAccountRequest(id_fb: number, username: string, email: string) {
  return axios.post(LINK_FACEBOOK_ACCOUNT_URL, {
    id_fb,
    username,
    email
  })
}

// Server should return AuthModel
export function loginGoogle(token: string, username: string, email: string, picture: string) {
  return axios.post<AuthModel>(LOGIN_GOOGLE_URL, {
    token,
    username,
    email,
    picture
  })
}

export function googleLinkAccountRequest(token: string, username: string, email: string, picture: string) {
  return axios.post(LINK_GOOGLE_ACCOUNT_URL, {
    token,
    username,
    email,
    picture
  })
}

// Server should return AuthModel
export function register(
  email: string,
  username: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    username: username,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean, message: string}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
