import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "./core/Auth";
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from "react-router-dom";
import { PasswordMeterComponent } from "../../../_metronic/assets/ts/components";

const API_URL = process.env.REACT_APP_API_URL

export const PASSWORD_RESET_API_URL = `${API_URL}/password_reset/`
export const PASSWORD_RESET_SUBMIT_API_URL = `${API_URL}/password_reset/submit/`

const passwordSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

const initialValues = {
    password: '',
    changepassword: '',
  }

export function PasswordReset(){
    const { token } = useParams();
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [messageRequest, setmessageRequest] = useState('')
    const [info, setInfo] = useState('')
    useEffect(() => {
        axios({
            method: 'GET',
            url: PASSWORD_RESET_API_URL + token
        })
        .then(function (r) {
            if(r.data.result_token == 'Something were wrong!' || r.data.result_token == 'Password Reset Token Expired!'){
                toast.error(r.data.result_token)
                return navigate('/auth/login');
            }
            PasswordMeterComponent.bootstrap()
            setData(r.data.result_token)
        })
    }, [])
    const formik = useFormik({
        initialValues,
        validationSchema: passwordSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            axios({
                method: 'POST',
                url: PASSWORD_RESET_SUBMIT_API_URL + token,
                data: {user_id: data['id'], new_password: values.password}
            })
            .then(function (r) {
                if(r.data.info){
                    setInfo(r.data.info)
                }
                if(r.data.result){
                    setLoading(false)
                    setmessageRequest(r.data.result)
                }
            })
        },
    })
    return (
        <>
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework animate__animated animate__fadeIn'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Change Your Password</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-semibold fs-4'>Using {data['email']}</div>
            <div className='text-gray-400 fw-semibold fs-4'>Username {data['username']}</div>
            {/* end::Link */}
            </div>
            {messageRequest &&
            <div className={'mb-10 p-8 rounded bg-light-' + info}>
                <div className={'text-center text-' + info}>{messageRequest}</div>
            </div>
            }
            {/* begin::Form group Password */}
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
                <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>Password</label>
                <div className='position-relative mb-3'>
                    <input
                    type='password'
                    placeholder='Password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                        'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                        'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                    />
                    {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                    )}
                </div>
                {/* begin::Meter */}
                <div
                    className='d-flex align-items-center mb-3'
                    data-kt-password-meter-control='highlight'
                >
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
                {/* end::Meter */}
                </div>
                <div className='text-muted'>
                Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bold text-dark fs-6'>Confirm Password</label>
                <input
                type='password'
                placeholder='Password confirmation'
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    },
                    {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    }
                )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                    </div>
                </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary fw-bold me-4'
                    disabled={formik.isSubmitting || !formik.isValid}
                    >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bold'
                    disabled={formik.isSubmitting || !formik.isValid}
                    >
                    Cancel
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
        </>
        )
}