import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { KinetoterapeutiWrapper } from '../pages/kinetoterapeuti/Kinetoterapeuti'
import { TerapiiWrapper } from '../pages/terapii/Terapii'
import { AdaugaTerapieWrapper } from '../pages/terapii/Adauga'
import { ForumWrapper } from '../pages/forum/Forum'
import { DonatiiWrapper } from '../pages/donatii/Donatii'
import { ActualizariWrapper } from '../pages/actualizari/Actualizari'
import { AdaugaKinetoWrapper } from '../pages/kinetoterapeuti/Adauga'
import { PendingsKinetoWrapper } from '../pages/kinetoterapeuti/Pendings'
import { useAuth } from '../modules/auth'
import { SearchKinetoWrapper } from '../pages/kinetoterapeuti/Search'
import { PromovareKinetoWrapper } from '../pages/kinetoterapeuti/Promovare'
import { ProfileWrapper } from '../pages/profile/Profile'
import { NotificationsWrapper } from '../pages/notifications/Notifications'
import { PlatiWrapper } from '../pages/plati/Plati'
import { ProgramareView } from '../pages/kinetoterapeuti/Programare'
import { ProgramariView } from '../pages/kinetoterapeuti/Programari'
import { ViewStire } from '../pages/forum/Partials/View'
import { EditareKinetoView } from '../pages/kinetoterapeuti/Editare'
import { PrivateMessagesView } from '../pages/messages/PrivateMessages'
import { PrivacyView } from '../pages/Policy/Privacy'
import { CookieView } from '../pages/Policy/Cookie'
import { CalendarKinetot } from '../pages/kinetoterapeuti/Calendar'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const {currentUser} = useAuth()
  const is_admin = currentUser?.admin

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='kinetoterapeuti' element={<KinetoterapeutiWrapper />} />
        <Route path='kinetoterapeuti/page/:page' element={<KinetoterapeutiWrapper />} />
        <Route path='kinetoterapeuti/adaugare/' element={<AdaugaKinetoWrapper />} />
        <Route path='kinetoterapeuti/search/' element={<SearchKinetoWrapper />} />
        <Route path='kinetoterapeuti/promovare/' element={<PromovareKinetoWrapper />} />
        <Route path='kinetoterapeuti/editare/' element={<EditareKinetoView />} />
        <Route path='kinetoterapeuti/calendar/' element={<CalendarKinetot />} />
        <Route path='profile/:user_id' element={<ProfileWrapper />} />
        <Route path="notifications" element={<NotificationsWrapper />} />
        <Route path="plati" element={<PlatiWrapper />} />
        <Route path='programare/:user_id' element={<ProgramareView />} />
        <Route path='programari' element={<ProgramariView />} />
        <Route path='terapii' element={<TerapiiWrapper />} />
        <Route path='terapii/adauga' element={<AdaugaTerapieWrapper />} />
        <Route path='forum' element={<ForumWrapper />} />
        <Route path='forum/:id_forum' element={<ViewStire />} />
        <Route path='donatii' element={<DonatiiWrapper />} />
        <Route path='actualizari' element={<ActualizariWrapper />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='messages' element={<PrivateMessagesView />} />
        <Route path='messages/:id_message' element={<PrivateMessagesView />} />
        <Route path='privacypolicy' element={<PrivacyView />} />
        <Route path='cookiespolicy' element={<CookieView />} />
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {is_admin && (
            <>
              <Route path='kinetoterapeuti/pendings/' element={<PendingsKinetoWrapper />} />
              <Route path='kinetoterapeuti/pendings/page/:page' element={<PendingsKinetoWrapper />} />
            </>
          )
        }
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-danger')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
