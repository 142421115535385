import axios from 'axios';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import { useAuth } from '../../../modules/auth';

const API_URL = process.env.REACT_APP_API_URL

export const PROFILE_UPDATED_DESCRIPTION_API_URL = `${API_URL}/profile/manage/`

export const EditDescription = (props) => {
    const {currentUser} = useAuth()
    const user_id = currentUser?.id
    const user_token = currentUser?.token
    const [valueQuill, setValueQuill] = useState(props.profile_about)
    const handleSubmitModal = () => {
        if(!valueQuill){
            return toast.error('Nu ai modificat nimic pentru a salva descrierea!')
        }
        let url_request;
        if(props.profile_about === 0){
            url_request = PROFILE_UPDATED_DESCRIPTION_API_URL + user_id + '/6/' 
        } else {
            url_request = PROFILE_UPDATED_DESCRIPTION_API_URL + user_id + '/7/' 
        }
        axios({
            method: 'POST',
            url: url_request,
            headers: {'Authorization': `${user_token}`},
            data:{about_me: valueQuill}
        })
        .then(function (r) {
            props.ProfileAbout(valueQuill)
            toast.success(r.data.message)
            props.toggle()
        });
    }
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}
            onHide={props.toggle}
            >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Edit your profile description
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="mb-10">
                    <ReactQuill theme="snow" value={valueQuill ? valueQuill : props.profile_about} onChange={setValueQuill} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.toggle} className="btn btn-light-dark btn-sm my-1 me-2">Close</Button>
                <Button onClick={() => handleSubmitModal()} className="btn btn-light-warning btn-sm my-1 me-2">Edit</Button>
            </Modal.Footer>
        </Modal>
    )
}