import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../../modules/auth";

const API_URL = process.env.REACT_APP_API_URL

export const PROFILE_MANAGE_API_URL = `${API_URL}/profile/manage/`
export const GET_COMMENTS_API_URL = `${API_URL}/get_comments/`
export const LIKE_COMMENT_API_URL = `${API_URL}/like_commnt/`

const ProfileComments = (props) => { 
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const [profileComments, setProfileComments] = useState<any[]>([])
    const [commentInput, setCommentInput] = useState('')
    const {currentUser} = useAuth()
    const is_logged = currentUser?.logged_in
    const user_token = currentUser?.token
    useEffect(() => {
        axios({
            method: 'POST',
            url: GET_COMMENTS_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {user_id: props.user_id}
        })
        .then(function (r) {
            setProfileComments([r.data.main_comments, r.data.sub_comments])
        });
    }, [props.user_id, reducerValue])
    function CommentProfile(comment_id=0){
        if(!commentInput){
            return toast.error('Completeaza campul!')
        }
        axios({
            method: 'POST',
            url: PROFILE_MANAGE_API_URL + props.user_id + '/4',
            headers: {'Authorization': `${user_token}`},
            data: {reply:commentInput, comment_id:comment_id}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    function ReplyCom(id_comment){
        const reply_input_value = (document.getElementById('reply_comm_input_' + id_comment) as HTMLInputElement).value
        if(!reply_input_value){return toast.error('Completeaza campul pentru a lasa un comentariu!')}
        axios({
            method: 'POST',
            url: PROFILE_MANAGE_API_URL + props.user_id + '/4',
            headers: {'Authorization': `${user_token}`},
            data: {reply:reply_input_value, comment_id:id_comment}
        })
        .then(function (r) {
            (document.getElementById('reply_comm_input_' + id_comment) as HTMLInputElement).value = ''
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    function LikeComment(id_comment, subcomment=0){
        axios({
            method: 'POST',
            url: LIKE_COMMENT_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {id_comment: id_comment, subcomment: subcomment}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    function DeleteComment(id_comment){
        axios({
            method: 'POST',
            url: PROFILE_MANAGE_API_URL + props.user_id + '/8',
            headers: {'Authorization': `${user_token}`},
            data: {id_comentariu: id_comment, profile_id: props.user_id}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    return (
        <div key={props} id="profile_comments" className="col-xl-6">
            <div className="card mb-5 mb-xl-8 animate__animated animate__fadeIn">
                <div className="card-body pb-0">
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center flex-grow-1">
                            <div className="symbol symbol-45px me-5">
                                <img src={props.avatar} alt="" />
                            </div>
                            <div className="d-flex flex-column">
                                <a className="text-gray-900 text-hover-primary fs-6 fw-bolder">{props.username}</a>
                                <span className="text-gray-400 fw-bold">Leave a comment down below</span>
                            </div>
                        </div>
                    </div>
                    {is_logged &&
                    <form id="kt_forms_widget_1_form" className="ql-quil ql-quil-plain pb-3">
                        <div id="kt_forms_widget_1_editor" className="py-6"></div>
                        <div className="separator"></div>
                        <div id="kt_forms_widget_1_editor_toolbar" className="ql-toolbar d-flex flex-stack py-2">
                            <textarea
                                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                data-kt-autosize='true'
                                rows={1}
                                placeholder='Reply..'
                                value={commentInput} onChange={(e) => {setCommentInput(e.target.value)}}
                            ></textarea>
                            <div className="me-n3">
                                <a onClick={() => CommentProfile()} className="btn btn-sm btn-success me-2">Reply</a>
                            </div>
                        </div>
                    </form>
                    }
                </div>
            </div>
            {profileComments[0]?.map((item) => {
            return ( 
                <div key={item.id} className="card mb-5 mb-xl-8 animate__animated animate__fadeIn" id={'comm_' + item.id}>
                    <div className="card-body pb-0 body_comment" id={item.id}>
                        <div className="d-flex align-items-center mb-3">
                            <div className="d-flex align-items-center flex-grow-1">
                                <div className="symbol symbol-45px me-5">
                                    <img src={item.avatar} alt="" />
                                </div>
                                <div className="d-flex flex-column">
                                    <a href={'/profile/' + item.bysqlid} className="text-gray-900 text-hover-primary fs-6 fw-bolder">{item.byusername}</a>
                                    <span className="text-gray-400 fw-bold">{item.date}</span>
                                </div>
                            </div>
                            {is_logged && props.isMyProfile &&
                            <div className="my-0">
                                <button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                    <span className="svg-icon svg-icon-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                            <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                            <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                            <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </button>
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                    <div className="menu-item px-3">
                                        <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                    </div>
                                    <div className="separator mb-3 opacity-75"></div>
                                    <div className="menu-item px-3 align-self-center">
                                        <a onClick={() => DeleteComment(item.id)} className="menu-link px-3">Delete</a>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="mb-7">
                            <div className="text-gray-800 mb-5" dangerouslySetInnerHTML={{__html: item.comment}}></div>
                            <div className="d-flex align-items-center mb-5">
                                <a className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="currentColor"></path>
                                            <rect x="6" y="12" width="7" height="2" rx="1" fill="currentColor"></rect>
                                            <rect x="6" y="7" width="12" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                    {item.subcomments_count}
                                </a>
                                <a onClick={() => LikeComment(item.id)} className={item.is_user_liked ? 'btn btn-sm btn-light btn-light-danger px-4 py-2' : 'btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'}>
                                    <span className="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    {item.likes}
                                </a>
                            </div>
                        </div>
                        {profileComments[1]?.filter((profileComments) => 
                            profileComments.comment_id == item.id
                        ).map((item_com) => {
                        return (
                        <div key={item_com.id} className="mb-7 ps-10">
                            <div className="d-flex">
                                <div className="symbol symbol-45px me-5">
                                    <img src={item_com.avatar} alt="" />
                                </div>
                                <div className="d-flex flex-column flex-row-fluid">
                                    <div className="d-flex align-items-center flex-wrap mb-1">
                                        <a href={'/profile/' + item_com.bysqlid} className="text-gray-800 text-hover-primary fw-bolder me-2">{item_com.byusername}</a>
                                        <span className="text-gray-400 fw-bold fs-7">{item_com.date}</span>
                                    </div>
                                    <span className="text-gray-800 fs-7 fw-normal pt-1" dangerouslySetInnerHTML={{__html: item_com.comment}}></span>
                                </div>
                                <div className="d-flex align-items-center mb-5">
                                    <a onClick={() => LikeComment(item_com.id, item_com.comment_id)} className={item_com.is_user_liked ? 'btn btn-sm btn-light btn-light-danger px-4 py-2' : 'btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'} id={"user_liked "} data-bs-toggle="users_liked" data-bs-delay-hide="1000" data-bs-placement="top" title="Like this comment">
                                        <span className="svg-icon svg-icon-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        {item_com.likes}
                                    </a>
                                </div>
                            </div>
                        </div>
                        );
                        })}
                        <div className="separator mb-4"></div>
                        <form className="position-relative mb-6">
                            <textarea id={'reply_comm_input_' + item.id} className="form-control border-0 p-0 pe-10 resize-none min-h-25px" data-kt-autosize="true" placeholder="Reply.."></textarea>
                            <div className="position-absolute top-0 end-0 me-n5">
                                {is_logged && 
                                <a onClick={() => ReplyCom(item.id)} className="btn btn-sm btn-success me-2">Reply</a>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                );
             })}
        </div>
    )
}

export {ProfileComments}