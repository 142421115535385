import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { useAuth } from "../../../modules/auth";
import FetchViewForum from "../Components/FetchViewForum";

const API_URL = process.env.REACT_APP_API_URL

export const FORUM_VIEW_API_URL = `${API_URL}/afiseaza_stire/`
export const FORUM_REPLY_API_URL = `${API_URL}/forum/post/reply/`
export const FORUM_DELETE_API_URL = `${API_URL}/forum/delete/`
export const FORUM_LIKE_API_URL = `${API_URL}/forum/post/like/`

export const GetFetch = async (token, id) => {
    const r = await FetchViewForum(token, id);
    return r.data
}

export const ViewStire = () => {
    const {id_forum} = useParams();
    const {currentUser} = useAuth()
    const is_admin = currentUser?.admin
    const user_token = currentUser?.token
    const is_logged = currentUser?.logged_in
    const [input, setInput] = useState('')
    const navigate = useNavigate();
    const { isError, isSuccess, isLoading, data, error } = useQuery(
        ["view_forum"],
        () => GetFetch(user_token, id_forum),
        { staleTime: 3000 }
    );
    const ReplyPost = (id) => {
        if(input.length < 3){
            return toast.error('Completeaza campul!')
        }
        axios({
            method: 'POST',
            url: FORUM_REPLY_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {text: input, id_stire: id}
        })
        .then(function (r) {
            toast.success(r.data.message)
        });
    }
    const RemovePost = (id) => {
        axios({
            method: 'POST',
            url: FORUM_DELETE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {id_stire: id}
        })
        .then(function (r) {
            toast.success(r.data.message)
            navigate('/forum/')
        });
    }
    const LikePost = (id) => {
        axios({
            method: 'POST',
            url: FORUM_LIKE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {id_stire: id}
        })
        .then(function (r) {
            toast.success(r.data.message)
        });
    }
    const breadcrumps: Array<PageLink> = [{
            title: data?.data.titlul,
            path: '/',
            isSeparator: false,
            isActive: true,
        }
    ]
    return (
        <>
        <React.Fragment>
            {data &&
            <div className="card mb-5 mb-xl-8 animate__animated animate__fadeIn">
                <div className="card-body pb-0">
                    <div className="d-flex align-items-center mb-3">
                        <div className="d-flex align-items-center flex-grow-1">
                            <div className="symbol symbol-45px me-5">
                                <img src={data.data.avatar} alt="" />
                            </div>
                            <div className="d-flex flex-column">
                                <Link to={'/profile/' + data.data.sqlid} className="text-gray-900 text-hover-primary fs-6 fw-bolder">{data.data.username}</Link>
                                <span className="text-gray-400 fw-bold">{data.data.date}</span>
                            </div>
                        </div>
                        {is_admin &&
                        <div className="my-0">
                            <button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                <span className="svg-icon svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </button>
                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                <div className="menu-item px-3">
                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                </div>
                                <div className="separator mb-3 opacity-75"></div>
                                <div className="menu-item px-3">
                                    <a onClick={() => RemovePost(data.data.id)} className="menu-link px-3 flex-center">Șterge</a>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="mb-7">
                        <p className="text-gray-800 mb-5" dangerouslySetInnerHTML={{__html: data.data.text}}></p>
                        <div className="d-flex align-items-center mb-5">
                            <a href="#" className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="currentColor"></path>
                                        <rect x="6" y="12" width="7" height="2" rx="1" fill="currentColor"></rect>
                                        <rect x="6" y="7" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                                {data?.data_reply_rows}
                            </a>
                            <a onClick={() => LikePost(data.data.id)} className={data.data.is_user_liked ? 'btn btn-sm btn-light btn-light-danger px-4 py-2' : 'btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'} data-bs-toggle="users_liked" data-bs-delay-hide="1000" data-bs-placement="top" title="Like this post">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                {data?.data_likes}
                            </a>
                        </div>
                    </div>
                    <div className="mb-7 ps-10">
                        {data.data_reply?.map((item, index) => { 
                        return (
                        <div key={index} className="d-flex mb-5">
                            <div className="symbol symbol-45px me-5">
                                <img src={item.avatar} alt="" />
                            </div>
                            <div className="d-flex flex-column flex-row-fluid">
                                <div className="d-flex align-items-center flex-wrap mb-1">
                                    <Link to={'/profile/' + item.sqlid} className="text-gray-800 text-hover-primary fw-bolder me-2">{item.username}</Link>
                                    <span className="text-gray-400 fw-bold fs-7">{item.date}</span>
                                </div>
                                <span className="text-gray-800 fs-7 fw-normal pt-1" dangerouslySetInnerHTML={{__html: item.reply}}></span>
                            </div>
                        </div>
                        );
                        })}
                    </div>
                    <div className="separator mb-4"></div>
                    {is_logged && 
                    <form className="position-relative mb-6">
                        <textarea value={input} onChange={(e) => {setInput(e.target.value)}} className="form-control border-0 p-0 pe-10 resize-none min-h-25px" data-kt-autosize="true" rows={1} placeholder="Reply.." style={{overflow: 'hidden', overflowWrap: 'break-word', height: '25px'}}></textarea>
                        <div className="position-absolute top-0 end-0 me-n5">
                            <a onClick={() => ReplyPost(data.data.id)} className="btn btn-light-success me-2 mb-2">Comentează</a>
                        </div>
                    </form>
                    }
                </div>
            </div>
            }
            <PageTitle breadcrumbs={breadcrumps}>{'Forum'}</PageTitle>
        </React.Fragment>
        </>
    )
}