import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { googleLinkAccountRequest } from "../../core/_requests";
import toast from "react-hot-toast";

export const GoogleLinkAccount = ({updateProfile}) => {

    async function responseGoogle (response: any){
        try {
            const user_data: any = jwt_decode(response.credential)
            // sub is user unique token from google
            const {data: auth} = await googleLinkAccountRequest(user_data.sub, user_data.name, user_data.email, user_data.picture);
            if(auth.message){
                updateProfile();
                toast.success(auth.message);
            }
        } catch (error: any) {
            toast.error(error.response.data.message)
        }
    }

    return (
        <GoogleOAuthProvider clientId="168252662838-50um8am6hh96cflgjdok44qoml2olrp7.apps.googleusercontent.com">
            <GoogleLogin 
                onSuccess={responseGoogle}
            />
        </GoogleOAuthProvider>
    )
}