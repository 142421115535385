import { useState } from "react"
import { useAuth } from "../../core/Auth"
import { UnLinkSocial, SocialEnum } from "../requests/Social"

const GoogleUnLinkAccount = ({updateProfile}) => {
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const [loadingGoogle, setLoadingGoogle] = useState(false)

    const UnLinkClicked = () => {
      setLoadingGoogle(true);
      UnLinkSocial(user_token, SocialEnum.Google)
        .finally(() => {
          setLoadingGoogle(false);
          updateProfile();
        });
    }
    
    return (
      <button onClick={() => UnLinkClicked()} className="btn btn-flex btn-light btn-active-light-danger btn-lg w-70 mb-5">
          <img alt="Logo Google" src="/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />
          {!loadingGoogle && <span className='indicator-label'>Unlink Google Account</span>}
          {loadingGoogle && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
      </button>
    )
}

export {GoogleUnLinkAccount}