import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../../modules/auth";

const API_URL = process.env.REACT_APP_API_URL

export const UPLOAD_AVATAR_API_URL = `${API_URL}/upload_avatar/`

const UploadAvatar = ({get_avatars, update_profile}) => {
  const {currentUser, setCurrentUser} = useAuth()
  const user_token = currentUser?.token
  const [selectImage, setSelectImage] = useState(null)

  const UpdateUserAvatar = (avatar) => {
    setCurrentUser({...currentUser, avatar_url: avatar});
  }

  const UploadAvatar = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    axios({
        method: 'POST',
        url: UPLOAD_AVATAR_API_URL,
        headers: {'Authorization': `${user_token}`, 'content-type': 'multipart/form-data'},
        data: formData
    })
    .then(function (r) {
        get_avatars();
        UpdateUserAvatar(r.data.avatar_updated)
        update_profile.UpdateProfile()
        toast.success(r.data.message)
        setSelectImage(null)
    });
  };
  
  const RemoveAvatar = () => {
      setSelectImage(null)
  }
  return (
    <>
      <div className="row mb-16">
          <label className="col-lg-4 col-form-label fw-bold fs-6">Upload New Avatar</label>
          <div className="col-lg-8">
              <div className="row">
                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                      <div className="symbol symbol-65px me-5">
                          <img alt="select" src={selectImage ? URL.createObjectURL(selectImage) : '/media/avatars/blank.png'}/>
                      </div>
                      <br />
                      <span className="me-3">{selectImage ? selectImage.name : 'No file chosen'}</span>
                      <button onClick={() => RemoveAvatar()} className="btn btn-sm btn-light btn-active-light-danger me-3" style={{display: selectImage ? '': 'none'}}>Remove</button>
                  </div>
                  <div className="col-lg-4 fv-row fv-plugins-icon-container">
                      <label htmlFor="changeAvatar" className="btn btn-sm btn-light btn-active-light-success me-3" style={{display: selectImage ? 'none': ''}}>Select</label>
                      <form onSubmit={UploadAvatar}>
                          <input
                              name="profile_avatar"
                              id="changeAvatar"
                              type="file"
                              accept="image/*"
                              style={{display: 'none'}}
                              onChange={(event) => {
                                  setSelectImage(event.target.files[0]);
                              }}
                              onClick={(e) => {
                                  (e.target as HTMLInputElement).value = null
                              }}
                          />
                          <button type="submit" disabled={!selectImage} className="btn btn-sm btn-light btn-active-light-success me-3" style={{display: selectImage ? '': 'none'}}>Upload</button>
                      </form>
                  <div className="fv-plugins-message-container invalid-feedback"></div></div>
              </div>
          </div>
      </div>
    </>
  )
}

export {UploadAvatar}