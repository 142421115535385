import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import { modulesQuill } from '../../../editorquill/modulesQuill';
import { useAuth } from '../../../modules/auth';

const API_URL = process.env.REACT_APP_API_URL

export const ACTUALIZARI_ADD_API_URL = `${API_URL}/updatepost/`

export const AddUpdate = (props) => {
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const [data, setData] = useState<any>({title: '', content: ''})
    const handleSubmitModal = () => {
        if(!data.content || !data.title){
            return toast.error('Completeaza campurile!')
        }
        axios({
            method: 'POST',
            url: ACTUALIZARI_ADD_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {title: data.title, content: data.content}
        })
        .then(function (r) {
            // actualizam datele imediat dupa add
            props.setData(r.data.data)
            toast.success(r.data.message)
            props.toggle()
        });
    }
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}
            onHide={props.toggle}
            >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Adauga o noua actualizare
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
            <div className="mb-10">
            <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">Titlul</label>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-lg-8 fv-row fv-plugins-icon-container">
                            <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={data.title} onChange={(e) => setData({title: e.target.value, content: data.content})}/>
                        </div>
                    </div>
                </div>
            </div>
                <ReactQuill theme="snow" value={data.content} onChange={(e) => setData({content: e, title: data.title})} modules={modulesQuill}/>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.toggle} className="btn btn-light-dark btn-sm my-1 me-2">Close</Button>
                <Button onClick={() => handleSubmitModal()} className="btn btn-light-success btn-sm my-1 me-2">Adauga</Button>
            </Modal.Footer>
        </Modal>
    )
}