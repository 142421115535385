import axios from "axios"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth"
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import { PropagateLoader } from "react-spinners"

const API_URL = process.env.REACT_APP_API_URL

export const KINETO_SEARCH_URL = `${API_URL}/kinetoterapeuti/get/search/`
export const KINETO_TOGGLE_API_URL = `${API_URL}/kinetoterapeuti/verificare/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Kinetoterapeuti',
        path: '/kinetoterapeuti',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const SearchKinetoView = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const is_admin = currentUser?.admin
    const is_logged = currentUser?.logged_in
    const [kineto, setKineto] = useState<any[]>([])
    const [query, setQuery] = useState('');
    const inputRef = useRef(null);
    useEffect(() => {
        axios({
            method: 'GET',
            url: KINETO_SEARCH_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            inputRef.current.focus();
            setKineto(response.data.result)
            setTimeout(function() {
                setIsLoading(false)
              }, 1500);
        });
    }, [reducerValue])
    function ToggleProfil(sqlid: any, toggle: any, sqlid_user: any){
        axios({
          method: 'POST',
          url: KINETO_TOGGLE_API_URL,
          data: {sqlid: sqlid, toggle: toggle, sqlid_user: sqlid_user},
          headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            forceUpdate()
            toast.success(response.data.message);
        });
    }
    return (
        <React.Fragment>
        <div className="card">
            <div className="card-header border-0 pt-6 justify-content-center">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <span className="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/>
                            <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/>
                            <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/>
                            <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/>
                            </svg>
                        </span>
                        <input ref={inputRef} type="text" className="form-control form-control-solid w-250px ps-14" placeholder="Începe căutarea" onChange={e=>setQuery(e.target.value)} />
                    </div>
                    {/* <div className="alert-text">&nbsp;&nbsp;<b>Caută printre kinetoterapeuți folosind căsuța din stânga. Orice cuvânt cheie va afișa un rezultat.</b></div> */}
                </div>
            </div>
        </div>
        <br />
        {isLoading &&
        <div className="d-flex justify-content-center">
            <PropagateLoader loading={true} color="#36d7b7" />
        </div>
        }
        {isLoading &&
        <br />
        }
        <TransitionGroup className={'row'}>
        {kineto.filter((kineto) => 
            kineto.descriere.toLowerCase().includes(query.toLowerCase()) ||
            kineto.orasul.toLowerCase().includes(query.toLowerCase()) ||
            kineto.username.toLowerCase().includes(query.toLowerCase()) ||
            kineto.facultatea.toLowerCase().includes(query.toLowerCase())
        ).map((item, index) => (
            <CSSTransition key={index} classNames={{
                enterActive: 'animate__animated animate__fadeIn'
            }} timeout={360}>
                {/* { item.sqlid != CreatorKineto.sqlid && */}
                <div id={"kineto-" + item.id} className="col-md-4 col-sm-6 feature-item rounded py-3">
                  <div className="card card-flush h-lg-100">
                      {item.promovat_status &&
                      <div className="ribbon ribbon-end ribbon-clip">
                          <div className="ribbon-label">
                              Promovat
                              <span className="ribbon-inner bg-success"></span>
                          </div>
                      </div>
                      }
                      <div className="card-body d-flex flex-center flex-column pt-12 p-9">
                          <div className="symbol symbol-65px symbol-circle mb-5">
                              <img src={item.avatar} alt="image" />
                              <div className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                          </div>
                          <Link to={"/profile/" + item.sqlid} className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{item.username}</Link>
                          <p className="mb-7" dangerouslySetInnerHTML={{__html: item.descriere}}></p>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Orașul în care activez:</strong> &nbsp;{item.orasul}
                              </div>
                          </div>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Contact:</strong> &nbsp;<a href="tel:+4{item.contact}" className="badge badge-light-primary fw-bolder me-auto px-4 py-3">{item.contact}</a>
                              </div>
                          </div>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Facultatea absolvită:</strong> &nbsp;{item.facultatea}
                              </div>
                          </div>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Anul absolvirii:</strong> &nbsp;{item.anul_absolvirii}
                              </div>
                          </div>
                      </div>
                      <div className="card-footer d-flex flex-center flex-wrap pt-0">
                          {is_admin && item.status != 1 &&
                            <a onClick={() => ToggleProfil(item.id, 1, item.sqlid)} className="btn btn-light-success btn-sm my-1 me-2">Acceptă profilul</a>
                          }
                          {is_admin && item.status != 2 &&
                            <a onClick={() => ToggleProfil(item.id, 2, item.sqlid)} className="btn btn-light-warning btn-sm my-1 me-2">Respinge profilul</a>
                          }
                          {item.status == 1 &&
                            <Link to={"/programare/" + item.sqlid} className="btn btn-light-success btn-sm my-1 me-2">Programare</Link>
                          }
                          {item.status == 2 &&
                            <Link to={"/programare/" + item.sqlid} className="btn btn-light-danger btn-sm my-1 me-2">Profil Respins</Link>
                          }
                          {/* {is_admin &&
                              <a onClick={() => StergereProfilModal(item.id, 1, item.sqlid, 'Ștergere profil ' + item.username, 'Esti sigur ca vrei sa stergi acest profil de kinetoterapeut ?')} className="btn btn-light-danger btn-sm my-1 me-2">Șterge</a>
                          } */}
                          {!is_logged &&
                              <Link to={"/login/"} className="btn btn-light-danger btn-sm my-1 me-2">Intră în cont pentru a te programa</Link>
                          }
                      </div>
                  </div>
                </div>
            </CSSTransition>
        ))}
        </TransitionGroup>
        </React.Fragment>
    )
}

const SearchKinetoWrapper = () => {
    const intl = useIntl()
    return (
      <>
        <PageTitle breadcrumbs={breadcrumps}>{'Căutare Kinetoterapeuți'}</PageTitle>
        <SearchKinetoView />
      </>
    )
}
  
export {SearchKinetoWrapper}