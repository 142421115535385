import axios from "axios"
import React, { useState } from "react"
import toast from "react-hot-toast"
import { useAuth } from "../../../modules/auth"

const API_URL = process.env.REACT_APP_API_URL

export const FORUM_POST_API_URL = `${API_URL}/forum/post/`

export const AdaugaStire = (props) => {
    const [input, setInput] = useState({category: '', titlul: '', descriere: ''})
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setInput({ ...input, [name]: value });
    };
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    function handleSubmit(){
        if(!input.descriere || !input.category || !input.titlul){
            return toast.error('Completeaza toate campurile!')
        }
        axios({
            method: 'POST',
            url: FORUM_POST_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {titlul: input.titlul, categoria: input.category, content: input.descriere}
        })
        .then(function (r) {
            toast.success(r.data.message)
        });
    }
    if(props.view == 'adauga'){
        return (
            <div className="card card-flush animate__animated animate__fadeIn" id="adauga" role="tabpanel">
                <div className="card-header pt-8">
                    <div className="card-title">
                        <h2>Completează câmpurile de mai jos</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="alert alert-info d-flex align-items-center p-5 mb-10">
                        <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M11 6.5C11 9 9 11 6.5 11C4 11 2 9 2 6.5C2 4 4 2 6.5 2C9 2 11 4 11 6.5ZM17.5 2C15 2 13 4 13 6.5C13 9 15 11 17.5 11C20 11 22 9 22 6.5C22 4 20 2 17.5 2ZM6.5 13C4 13 2 15 2 17.5C2 20 4 22 6.5 22C9 22 11 20 11 17.5C11 15 9 13 6.5 13ZM17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13Z" fill="currentColor"/>
                            <path d="M17.5 16C17.5 16 17.4 16 17.5 16L16.7 15.3C16.1 14.7 15.7 13.9 15.6 13.1C15.5 12.4 15.5 11.6 15.6 10.8C15.7 9.99999 16.1 9.19998 16.7 8.59998L17.4 7.90002H17.5C18.3 7.90002 19 7.20002 19 6.40002C19 5.60002 18.3 4.90002 17.5 4.90002C16.7 4.90002 16 5.60002 16 6.40002V6.5L15.3 7.20001C14.7 7.80001 13.9 8.19999 13.1 8.29999C12.4 8.39999 11.6 8.39999 10.8 8.29999C9.99999 8.19999 9.20001 7.80001 8.60001 7.20001L7.89999 6.5V6.40002C7.89999 5.60002 7.19999 4.90002 6.39999 4.90002C5.59999 4.90002 4.89999 5.60002 4.89999 6.40002C4.89999 7.20002 5.59999 7.90002 6.39999 7.90002H6.5L7.20001 8.59998C7.80001 9.19998 8.19999 9.99999 8.29999 10.8C8.39999 11.5 8.39999 12.3 8.29999 13.1C8.19999 13.9 7.80001 14.7 7.20001 15.3L6.5 16H6.39999C5.59999 16 4.89999 16.7 4.89999 17.5C4.89999 18.3 5.59999 19 6.39999 19C7.19999 19 7.89999 18.3 7.89999 17.5V17.4L8.60001 16.7C9.20001 16.1 9.99999 15.7 10.8 15.6C11.5 15.5 12.3 15.5 13.1 15.6C13.9 15.7 14.7 16.1 15.3 16.7L16 17.4V17.5C16 18.3 16.7 19 17.5 19C18.3 19 19 18.3 19 17.5C19 16.7 18.3 16 17.5 16Z" fill="currentColor"/>
                            </svg>
                        </span>
                        <div className="d-flex flex-column">
                            <h4 className="mb-1 text-info">Adaugă o știre în acest forum.</h4>
                        </div>
                    </div>
                    <div className="fv-row row mb-15">
                        <div className="col-md-3 d-flex align-items-center">
                            <label className="fs-6 fw-bold">Secțiunea</label>
                        </div>
                        <div className="col-md-9" data-select2-id="select2-data-77-533l">
                            <div className="mb-10">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" value="0" name="category" onChange={handleChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioChecked" style={{marginLeft: '2rem'}}>Kinetoterapie</label>
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" value="1" name="category" onChange={handleChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioChecked" style={{marginLeft: '2rem'}}>Altele</label>
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" value="2" name="category" onChange={handleChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioChecked" style={{marginLeft: '2rem'}}>Tickets</label>
                                </div>
                            </div>
                            <span className="form-text text-muted">Alege una dintre opțiuni</span>
                        </div>
                    </div>
                    <div className="fv-row row mb-15">
                        <div className="col-md-3 d-flex align-items-center">
                            <label className="fs-6 fw-bold">Titlul</label>
                        </div>
                        <div className="col-md-9" data-select2-id="select2-data-77-533l">
                            <input className="form-control" placeholder="" type="text" name="titlul" value={input!.titlul} onChange={handleChange} />
                            <span className="form-text text-muted">De la sine înteles</span>
                        </div>
                    </div>
                    <div className="fv-row row mb-15">
                        <div className="col-md-3 d-flex align-items-center">
                            <label className="fs-6 fw-bold">Descriere</label>
                        </div>
                        <div className="col-md-9" data-select2-id="select2-data-77-533l">
                            <textarea className="form-control" name="descriere" maxLength={3000} placeholder="" rows={6} value={input!.descriere} onChange={handleChange}></textarea>
                            <span className="form-text text-muted">Descrie știrea</span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row mt-12">
                        <div className="col-md-9 offset-md-3">
                            <button onClick={handleSubmit} type="button" className="btn btn-primary">
                                <span className="indicator-label">Submit</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}