/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import { useAuth } from '../auth'
import React, { useReducer, useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill'

const API_URL = process.env.REACT_APP_API_URL

export const PROFILE_MANAGE_API_URL = `${API_URL}/profile/manage/`
export const SEND_MESSAGE_API_URL = `${API_URL}/private_messages/compose_message/`
export const TOGGLE_ADMIN_API_URL = `${API_URL}/toggle_admin/`

const ProfileHeader = (props) => {
    // let [friendship, setFriendship] = useState(props.friendship)
    const [show, setShow] = useState(false)
    const {currentUser} = useAuth()
    const [modalData, setModalData] = useState({title: ''})
    const [valueQuill, setValueQuill] = useState('');
    const [showRating, setShowRating] = useState(props.user_rating)
    const current_user_id = currentUser?.id
    const user_token = currentUser?.token
    const is_admin = currentUser?.admin
    const user = props.data;
    let userStatus = ''
    const [modalShow, setModalShow] = useState(false);
    if(user.Admin){
        userStatus = 'Admin'
    } else {
        userStatus = 'Member'
    }
    setTimeout(function () {
        setShow(true)
    }, 1000);
    function ToggleFriend(){
        let action;
        if(props.friendship){
            action = 1
        } else {
            action = 0
        }
        axios({
            method: 'POST',
            url: PROFILE_MANAGE_API_URL + user.id + '/' + action,
            headers: {'Authorization': `${user_token}`},
        })
        .then(function (r) {
            if(action){
                props.Friendship(false)
            } else {
                props.Friendship(true)
            }
            toast.success(r.data.message)
        });
    }
    const handleSubmitModal = () => {
        if(!user.id || !modalData.title || !valueQuill){
            return toast.error('Completeaza campurile!')
        }
        axios({
            method: 'POST',
            url: SEND_MESSAGE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data:{title: modalData.title, content: valueQuill, receiver_id: user.id}
        })
        .then(function (r) {
            toast.success(r.data.message)
            setModalShow(false);
            setValueQuill('')
            setModalData({title: ''})
        });
    }
    function handleCancelModal(){
        setModalShow(false);
        setValueQuill('')
        setModalData({title: ''})
    }
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setModalData({ ...modalData, [name]: value });
    }
    const ToggleAdmin = (action) => {
        axios({
            method: 'POST',
            url: TOGGLE_ADMIN_API_URL,
            headers: {'Authorization': `${user_token}`},
            data:{user_id: user.id, action: action}
        })
        .then(function (r) {
            props.UpdateProfile()
            toast.success(r.data.message)
        });
    }
    const submitRating = (e) => {
        if(user.id == current_user_id){ return toast.error('Nu iti poti da singur rate!')}
        axios({
            method: 'POST',
            url: PROFILE_MANAGE_API_URL + user.id + '/2/',
            headers: {'Authorization': `${user_token}`},
            data: {newRating: e.target.value}
        })
        .then(function (r) {
            // props.Rating()
            setShowRating(e.target.value)
            toast.success(r.data.message)
        });
    }
    const setRating = (value) => {
        let set_value = showRating ? showRating : props.user_rating
        // facem asa pt ca pe sistemul vechi faceam cu jumatati
        if(value == Math.round(set_value)){
            return true
        } else {
            return false
        }
    }
    return (
        <React.Fragment>
        {show &&
        <div className='card mb-5 mb-xl-10 animate__animated animate__slideInDown animate__faster 500ms'>
            <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                        <img src={toAbsoluteUrl(user.avatar)} alt='Avatar' />
                    </div>
                </div>

                <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
                        {user.username}
                        </a>
                        <a>
                        <KTSVG
                            path='/media/icons/duotune/general/gen026.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                        </a>
                        <span className={user.Admin ? 'btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3' : 'btn btn-sm btn-light-primary fw-bolder ms-2 fs-8 py-1 px-3'}>{userStatus}</span>
                        <span className={'btn btn-sm btn-light-primary fw-bolder ms-2 fs-8 py-1 px-3'}>{props.statusUser}</span>
                    </div>

                    <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                        {user.Admin > 0 && 
                        <a
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                        <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-4 me-1'
                        />
                        Admin
                        </a>
                        }
                        <a
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                        <KTSVG
                            path='/media/icons/duotune/general/gen018.svg'
                            className='svg-icon-4 me-1'
                        />
                        {user.Location}
                        </a>
                        <a
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                        >
                        <KTSVG
                            path='/media/icons/duotune/communication/com011.svg'
                            className='svg-icon-4 me-1'
                        />
                        {user.email}
                        </a>
                    </div>
                    </div>

                    <div className='d-flex my-4'>
                    {!props.isMyProfile && is_admin &&
                    <a
                        onClick={user.Admin ? () => ToggleAdmin('remove') : () => ToggleAdmin('add')}
                        className={user.Admin ? 'btn btn-sm btn-danger me-3' : 'btn btn-sm btn-success me-3'}
                    >
                        {user.Admin ? 'Remove Admin' : 'Make Admin'}
                    </a>
                    }
                    {!props.isMyProfile &&
                    <a onClick={() => ToggleFriend()} className={props.friendship ? 'btn btn-sm btn-danger me-2' : 'btn btn-sm btn-success me-2'} id='kt_user_follow_button'>
                        <KTSVG
                        path='/media/icons/duotune/arrows/arr012.svg'
                        className='svg-icon-3 d-none'
                        />
                        <span className='indicator-label'>{props.friendship ? 'Remove Friend' : 'Add Friend'}</span>
                        <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    </a>
                    }
                    {!props.isMyProfile &&
                    <a
                        onClick={() => setModalShow(true)}
                        className='btn btn-sm btn-light me-3'
                    >
                        Send a message
                    </a>
                    }
                    </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                            <KTSVG
                            path='/media/icons/duotune/arrows/arr066.svg'
                            className='svg-icon-3 svg-icon-success me-2'
                            />
                            <div className='fs-2 fw-bold'>Last seen</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>{user.last_seen}</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                            <KTSVG
                            path='/media/icons/duotune/arrows/arr065.svg'
                            className='svg-icon-3 svg-icon-danger me-2'
                            />
                            <div className='fs-2 fw-bold'>Registered</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>{user.Registered}</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                            <KTSVG
                            path='/media/icons/duotune/arrows/arr066.svg'
                            className='svg-icon-3 svg-icon-success me-2'
                            />
                            <div className='fs-2 fw-bold'>Phone Number</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>{user.Phone}</div>
                        </div>
                    </div>
                    </div>

                    <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                        <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                            <span className='fw-semibold fs-6 text-gray-400'>Rating</span>
                        </div>
                        <div className='h-5px mx-3 w-100 mb-3'>
                            <div className="rating">
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="0"
                                    checked={setRating(0)}
                                    onClick={(e) => submitRating(e)}
                                    onChange={() => {}}
                                    type="radio"
                                    id="kt_rating_2_input_0"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_1">
                                    <KTSVG
                                    path="/media/icons/duotune/general/gen029.svg"
                                    className="svg-icon svg-icon-1"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="1"
                                    checked={setRating(1)}
                                    onClick={(e) => submitRating(e)}
                                    onChange={() => {}}
                                    type="radio"
                                    id="kt_rating_2_input_1"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_2">
                                    <KTSVG
                                    path="/media/icons/duotune/general/gen029.svg"
                                    className="svg-icon svg-icon-1"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="2"
                                    checked={setRating(2)}
                                    onClick={(e) => submitRating(e)}
                                    onChange={() => {}}
                                    type="radio"
                                    id="kt_rating_2_input_2"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_3">
                                    <KTSVG
                                    path="/media/icons/duotune/general/gen029.svg"
                                    className="svg-icon svg-icon-1"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="3"
                                    checked={setRating(3)}
                                    onClick={(e) => submitRating(e)}
                                    onChange={() => {}}
                                    type="radio"
                                    id="kt_rating_2_input_3"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_4">
                                    <KTSVG
                                    path="/media/icons/duotune/general/gen029.svg"
                                    className="svg-icon svg-icon-1"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="4"
                                    checked={setRating(4)}
                                    onClick={(e) => submitRating(e)}
                                    onChange={() => {}}
                                    type="radio"
                                    id="kt_rating_2_input_4"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_5">
                                    <KTSVG
                                    path="/media/icons/duotune/general/gen029.svg"
                                    className="svg-icon svg-icon-1"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="5"
                                    checked={setRating(5)}
                                    onClick={(e) => submitRating(e)}
                                    onChange={() => {}}
                                    type="radio"
                                    id="kt_rating_2_input_5"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="d-flex overflow-auto h-55px">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                    <li className="nav-item">
                        <a id="profile_view" className="nav-link active" data-bs-toggle="tab" href="#profile_view_container">Profile Overview</a>
                    </li>
                    {user.id == current_user_id &&
                    <li className="nav-item">
                        <a id="settings_view" className="nav-link" data-bs-toggle="tab" href="#settings_view_container">Settings</a>
                        <a id="avatars_view" className="nav-link" data-bs-toggle="tab" href="#avatars_view_container">Avatars</a>
                    </li>
                    }
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#friends_view_container">{user.id == current_user_id ? 'My friends' : user.username + ' Friends'}</a>
                    </li>
                </ul>
            </div>
            </div>
        </div>
        }
        {!props.isMyProfile && 
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Send a message to {user.username}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <div className="mb-10">
                <label style={{display: 'flex'}}className="form-label">Title</label>
                <input value={modalData.title} onChange={(e)=> handleChange(e)} name="title" type="text" className="form-control" placeholder="title of the conversation" />
            </div>
            <div className="mb-10">
                <ReactQuill theme="snow" value={valueQuill} onChange={setValueQuill} />
            </div>
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={handleCancelModal} className="btn btn-light-dark btn-sm my-1 me-2">Close</Button>
        <Button onClick={() => handleSubmitModal()} className="btn btn-light-success btn-sm my-1 me-2">Send</Button>
        </Modal.Footer>
        </Modal>
        }
        </React.Fragment>
    )
}

export {ProfileHeader}
