import axios from 'axios';
import React, { useEffect, useReducer, useState, FC } from 'react';
import toast from 'react-hot-toast';
import { KTSVG } from '../../../_metronic/helpers';
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth';
import { AddUpdate } from './Components/AddUpdate';
import { EditUpdate } from './Components/EditUpdate';

const API_URL = process.env.REACT_APP_API_URL

export const ACTUALIZARI_GET_API_URL = `${API_URL}/actualizari_get/`
export const ACTUALIZARI_REMOVE_API_URL = `${API_URL}/deleteupdate/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ActualizariPageView: FC = () => {
    const [data, setData] = useState<any[]>([])
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const is_admin = currentUser?.admin
    const [showModal, setShowModal] = useState(false)
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [itemEdit, setItemEdit] = useState<any[]>([])
    const toggleModal = (item) => {
        setItemEdit(item)
        setShowModal(!showModal)
    }
    const toggleModalAdd = () => {
        setShowModalAdd(!showModalAdd)
    }
    const RemoveUpdate = (id) => {
        axios({
            method: 'POST',
            url: ACTUALIZARI_REMOVE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {id_update: id}
        })
        .then(function (r) {
            toast.success(r.data.message)
            setData(r.data.data)
        });
    }
    useEffect(() => {
        axios({
            method: 'get',
            url: ACTUALIZARI_GET_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            setData(response.data.data)
        });
    }, [])
    return (
        <React.Fragment>
        <div className="card card-custom gutter-b animate__animated animate__fadeIn">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">Ultimele actualizări ale platformei</h3>
                </div>
                { is_admin &&
                <div className="card-toolbar">
                    <a onClick={() => toggleModalAdd()} className="btn btn-sm btn-success font-weight-bold">
                    <i className="flaticon2-cube"></i>Create update</a>
                </div>
                }
            </div>
            <div className="card-body">
                <div className="accordion" id="kt_accordion_1">
                    {data.map((item, index) => {
                    return (
                        <div key={item.id} className="accordion-item" id={item.id}>
                            { is_admin &&
                            <div className="accordion-header">
                                <a onClick={() => RemoveUpdate(item.id)} className="btn btn-sm btn-danger font-weight-bold me-2">
                                    Remove
                                </a>
                                <a onClick={() => toggleModal(item)} className="btn btn-sm btn-warning me-3 align-self-center">Edit</a>
                            </div>
                            }
                            <h2 className="accordion-header " id={"kt_accordion_1_header_" + item.id}>
                                <button className={index == 0 ? 'accordion-button fs-4 fw-bold' : 'accordion-button fs-4 fw-bold collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={"#kt_accordion_1_body_" + item.id} aria-expanded="true" aria-controls={"kt_accordion_1_body_" + item.id}>
                                    {item.title} ({item.date})
                                </button>
                            </h2>
                            <div id={"kt_accordion_1_body_" + item.id} className={index == 0 ? 'accordion-collapse show' : 'accordion-collapse collapse'} aria-labelledby={"kt_accordion_1_header_" + item.id} data-bs-parent="#kt_accordion_1">
                                <div className="accordion-body" dangerouslySetInnerHTML={{__html: item.content}}>
                                    
                                </div>
                            </div>
                        </div>
                    );
                    })}
                </div>
            </div>
        </div>
        <EditUpdate showModal={showModal} toggle={toggleModal} item={itemEdit}/>
        { is_admin &&
        <AddUpdate showModal={showModalAdd} toggle={toggleModalAdd} items={data} setData={setData}/>
        }   
        </React.Fragment>
    )
} 

const ActualizariWrapper = () => {
return (
    <>
    <PageTitle breadcrumbs={breadcrumps}>{'Actualizari'}</PageTitle>
    <ActualizariPageView />
    </>
)
}
  
export {ActualizariWrapper}