import { useState } from "react"
import { useAuth } from "../../core/Auth"
import { UnLinkSocial, SocialEnum } from "../requests/Social"

const FacebookUnLinkAccount = ({updateProfile}) => {
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const [loadingFB, setLoadingFB] = useState(false)

    const UnLinkClicked = () => {
      setLoadingFB(true);
      UnLinkSocial(user_token, SocialEnum.Facebook)
        .finally(() => {
            setLoadingFB(false);
            updateProfile();
        });
    }

    return (
      <button onClick={() => UnLinkClicked()} className="btn btn-flex btn-light btn-active-light-danger btn-lg w-70 mb-5">
          <img alt="Logo Facebook" src="/media/svg/brand-logos/facebook-2.svg" className="h-20px me-3" />
          {!loadingFB && <span className='indicator-label'>Unlink Facebook Account</span>}
          {loadingFB && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
      </button>
    )
}

export {FacebookUnLinkAccount}