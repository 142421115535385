import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {getUserByToken, loginFacebook} from '../../core/_requests'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useAuth} from '../../core/Auth'
import toast from 'react-hot-toast'
import { useState } from 'react'

export const FacebookAuth = () => {
    const [loadingFB, setLoadingFB] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    async function responseFacebook (response: any){
        setLoadingFB(true)
        try {
          const {data: auth} = await loginFacebook(response.userID, response.name, response.accessToken, response.email);
          saveAuth(auth)
          if(auth.message){
            toast.success(auth.message);
          }
          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)
        } catch (error: any) {
          toast.error(error.response.data.message)
          setLoadingFB(false)
        }
    }
    return (
        <FacebookLogin
          appId="771121446768214"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook} 
          cssClass="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
          icon={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
          textButton="Continue with Facebook"
          render={renderProps => (
            <button onClick={renderProps.onClick} className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                className='h-20px me-3'
              />
              {!loadingFB && <span className='indicator-label'>Continue With Facebook</span>}
              {loadingFB && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              </button>
          )}
          />
    )
}