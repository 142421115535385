import axios from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PropagateLoader } from 'react-spinners'

const API_URL = process.env.REACT_APP_API_URL

export const KINETO_ALL_API_URL = `${API_URL}/kinetoterapeuti/get/`
export const KINETO_TOGGLE_API_URL = `${API_URL}/kinetoterapeuti/verificare/`
export const KINETO_STERGERE_API_URL = `${API_URL}/kinetoterapeuti/stergere/`

const breadcrumps: Array<PageLink> = [
  {
      title: 'Home',
      path: '/',
      isSeparator: false,
      isActive: false,
  },
  {
      title: 'Kinetoterapeuti',
      path: '/',
      isSeparator: true,
      isActive: true,
  },
]

// async function initPageData(url) {
//   return await MakeRequest('get', url);
// }

const KinetoCard = (page: any) => {
  const [isLoading, setIsLoading] = useState(true)
  const [modalData, setModalData] = useState<any>({})
  const [modalShow, setModalShow] = useState(false);
  const [showAdaugaButton, setAdaugaButton] = useState(false)
  const [pendingsCount, setPendingsCount] = useState<any>(0)
  const [paginate_links, setPaginate_links] = useState<any>()
  const [paginate_info, setPaginate_info] = useState<any>()
  const [kineto, setKineto] = useState<any[]>([])
  const [CreatorKineto, setCreatorKineto] = useState<any>()
  const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
  const {currentUser, logout} = useAuth()
  const user_token = currentUser?.token
  const is_admin = currentUser?.admin
  const is_logged = currentUser?.logged_in
  let url_request = KINETO_ALL_API_URL;
  if(page.page > 1){
    url_request = KINETO_ALL_API_URL + page.page;
  }
  useEffect(() => {
    axios({
        method: 'get',
        url: url_request,
        headers: {'Authorization': `${user_token}`}
    })
    .then(function (response) {
        // console.log(response.data)
        setPendingsCount(response.data.pendings_count)
        setCreatorKineto(response.data.creator_kineto)
        setKineto(response.data.result)
        setPaginate_links(response.data.paginate_links)
        setPaginate_info(response.data.paginate_info)
        if(!response.data.creator_kineto){
          setAdaugaButton(true)
        }
        setTimeout(function() {
          setIsLoading(false)
        }, 1500);
    })
    .catch(function (error){
      logout();
      if(error.response){
        toast.error(error.response.data.message);
      }
    });
  }, [reducerValue, page.page])
  // toggle = 1 -> acceptat, 2 -> respins
  function ToggleProfil(sqlid: any, toggle: any, sqlid_user: any){
    axios({
      method: 'POST',
      url: KINETO_TOGGLE_API_URL,
      data: {sqlid: sqlid, toggle: toggle, sqlid_user: sqlid_user},
      headers: {'Authorization': `${user_token}`}
    })
    .then(function (response) {
        forceUpdate()
        toast.success(response.data.message);
    })
    .catch(function (error){
      logout();
      if(error.response){
        toast.error(error.response.data.message);
      }
    });
  }
  function StergereProfilModal(id: any, username: any, sqlid: any, title: any, text: any){
    setModalData({'id': id, 'username': username, 'sqlid': sqlid, 
                  'title': title, 'text': text})
    setModalShow(true)
  }
  function handleSubmitModal (){
    axios({
        method: 'POST',
        url: KINETO_STERGERE_API_URL,
        headers: {'Authorization': `${user_token}`},
        data: {sqlid: modalData.id, kineto_sqlid: modalData.sqlid}
    })
    .then(function (response) {
      if(response.data.message){
        toast.success(response.data.message);
        forceUpdate()
        setModalShow(false)
      }
    })
    .catch(function (error){
      logout();
      if(error.response){
        toast.error(error.response.data.message);
      }
    });
  }
  function handleCancelModal(){
    setModalShow(false);
  }
  return(
    <React.Fragment>
      {is_logged && 
      <div className="card">
        <div className="card-header border-0 pt-6">
            <div className="card-title">
                <span className="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/>
                    <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/>
                    <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/>
                    <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/>
                    </svg>
                </span>
                <div className="alert-text"><b>Adaugă-ți profilul de kinetoterapeut in baza noastră de date</b></div>
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-subscription-table-toolbar="base">
                    {is_admin && 
                    <Link to={"/kinetoterapeuti/pendings/"} className="btn btn-light-warning me-3">Pendings(<span className="font-weight-bolder">{pendingsCount}</span>)</Link>
                    }
                    {showAdaugaButton &&
                    <Link className="btn btn-light-primary me-3" to={"/kinetoterapeuti/adaugare/"}>Adaugă</Link>
                    }
                </div>
            </div>
        </div>
      </div>
      }
      <br>
      </br>
      <div className="card">
        <div className="card-header border-0 pt-6">
            <div className="card-title">
                <Link to={"/kinetoterapeuti/search/"} className="svg-icon svg-icon-danger svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"/>
                    <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"/>
                    </svg>
                </Link>
                <div className="pagination">
                    <ul className="kt-pagination__links" dangerouslySetInnerHTML={{__html: paginate_links}}>

                    </ul>
                </div>
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-subscription-table-toolbar="base">
                    <div className="kt-pagination__toolbar">
                        <span className="pagination__desc" dangerouslySetInnerHTML={{__html: paginate_info}}>

                        </span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <br />
      {isLoading &&
      <div className="d-flex justify-content-center">
          <PropagateLoader loading={true} color="#36d7b7" />
      </div>
      }
      {isLoading &&
      <br />
      }
      <TransitionGroup className={'row'}>
        {CreatorKineto && 
        <CSSTransition key={CreatorKineto.id} classNames={{
            enterActive: 'animate__animated animate__pulse animate__repeat-3'
        }} timeout={4000}>
        <div id={"kineto-" + CreatorKineto.id} className="col-md-4 col-sm-6 feature-item rounded py-3">
            <div className={'card card-flush h-lg-100' + (CreatorKineto.status == 0 ? ' bg-light-warning' : CreatorKineto.status == 1 ? ' bg-light-success' : CreatorKineto.status == 2 ? ' bg-light-danger' : '')}>
                {CreatorKineto.promovat_status && 
                <div className="ribbon ribbon-end ribbon-clip">
                    <div className="ribbon-label">
                        Promovat
                        <span className="ribbon-inner bg-success"></span>
                    </div>
                </div>
                }
                {CreatorKineto.status == 0 && 
                <div className="ribbon ribbon-start ribbon-clip">
                    <div className="ribbon-label">
                        În așteptare
                        <span className="ribbon-inner bg-warning"></span>
                    </div>
                </div>
                }
                {CreatorKineto.status == 1 && 
                <div className="ribbon ribbon-start ribbon-clip">
                    <div className="ribbon-label">
                        Profil Activ
                        <span className="ribbon-inner bg-success"></span>
                    </div>
                </div>
                }
                {CreatorKineto.status == 2 && 
                <div className="ribbon ribbon-start ribbon-clip">
                    <div className="ribbon-label">
                        Profil Respins
                        <span className="ribbon-inner bg-danger"></span>
                    </div>
                </div>
                }
                <div className="card-body d-flex flex-center flex-column pt-12 p-9">
                    <div className="symbol symbol-65px symbol-circle mb-5">
                        <img src={CreatorKineto.avatar} alt="image" />
                        <div className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                    </div>
                    <Link to={"/profile/" + CreatorKineto.sqlid} className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{CreatorKineto.username}</Link>
                    <p className="mb-7" dangerouslySetInnerHTML={{__html: CreatorKineto.descriere}}></p>
                    <div className="d-flex flex-column text-gray-600">
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3"></span>
                            <strong>Orașul în care activez:</strong> &nbsp;{CreatorKineto.orasul}
                        </div>
                    </div>
                    <div className="d-flex flex-column text-gray-600">
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3"></span>
                            <strong>Contact:</strong> &nbsp;<a href={"tel:+4" + CreatorKineto.contact } className="badge badge-light-primary fw-bolder me-auto px-4 py-3">{CreatorKineto.contact}</a>
                        </div>
                    </div>
                    <div className="d-flex flex-column text-gray-600">
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3"></span>
                            <strong>Facultatea absolvită:</strong> &nbsp;{CreatorKineto.facultatea}
                        </div>
                    </div>
                    <div className="d-flex flex-column text-gray-600">
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3"></span>
                            <strong>Anul absolvirii:</strong> &nbsp;{CreatorKineto.anul_absolvirii}
                        </div>
                    </div>
                    <div className="d-flex flex-column text-gray-600">
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3"></span>
                            <strong>Promovat: </strong> &nbsp;<span className="badge badge-primary">{CreatorKineto.promovat}</span>
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex flex-center flex-wrap pt-0">
                    {is_admin && CreatorKineto.status != 1 && 
                      <a onClick={() => ToggleProfil(CreatorKineto.id, 1, CreatorKineto.sqlid)} className="btn btn-light-success btn-sm my-1 me-2">Acceptă profilul</a>
                    }
                    {is_admin && CreatorKineto.status != 2 && 
                      <a onClick={() => ToggleProfil(CreatorKineto.id, 2, CreatorKineto.sqlid)} className="btn btn-light-danger btn-sm my-1 me-2">Respinge profilul</a>
                    }
                    {!CreatorKineto.promovat_status && 
                      <Link to={"/kinetoterapeuti/promovare/"} className="btn btn-light-success btn-sm my-1 me-2">Promovează</Link>
                    }
                    <Link to={"/kinetoterapeuti/editare/"} className="btn btn-light-warning btn-sm my-1 me-2">Editează</Link>
                    <a onClick={() => StergereProfilModal(CreatorKineto.id, 1, CreatorKineto.sqlid, 'Șterge profilul tau de kinetoterapeut', 'Esti sigur ca vrei sa iti stergi profilul de kinetoterapeut ?')} className="btn btn-light-danger btn-sm my-1 me-2">Șterge</a>
                </div>
            </div>
        </div>
        </CSSTransition>
        }
        {kineto.map((item, index) => {
        return (
            <CSSTransition key={index} classNames={{
                enterActive: 'animate__animated animate__fadeIn'
            }} timeout={360}>
                {/* { item.sqlid != CreatorKineto.sqlid && */}
                <div id={"kineto-" + item.id} className="col-md-4 col-sm-6 feature-item rounded py-3">
                  <div className="card card-flush h-lg-100">
                      {item.promovat_status &&
                      <div className="ribbon ribbon-end ribbon-clip">
                          <div className="ribbon-label">
                              Promovat
                              <span className="ribbon-inner bg-success"></span>
                          </div>
                      </div>
                      }
                      <div className="card-body d-flex flex-center flex-column pt-12 p-9">
                          <div className="symbol symbol-65px symbol-circle mb-5">
                              <img src={item.avatar} alt="image" />
                              <div className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                          </div>
                          <Link to={"/profile/" + item.sqlid} className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{item.username}</Link>
                          <p className="mb-7" dangerouslySetInnerHTML={{__html: item.descriere}}></p>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Orașul în care activez:</strong> &nbsp;{item.orasul}
                              </div>
                          </div>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Contact:</strong> &nbsp;<a href="tel:+4{item.contact}" className="badge badge-light-primary fw-bolder me-auto px-4 py-3">{item.contact}</a>
                              </div>
                          </div>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Facultatea absolvită:</strong> &nbsp;{item.facultatea}
                              </div>
                          </div>
                          <div className="d-flex flex-column text-gray-600">
                              <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>
                                  <strong>Anul absolvirii:</strong> &nbsp;{item.anul_absolvirii}
                              </div>
                          </div>
                      </div>
                      <div className="card-footer d-flex flex-center flex-wrap pt-0">
                          {is_admin && item.status != 1 &&
                            <a onClick={() => ToggleProfil(item.id, 1, item.sqlid)} className="btn btn-light-success btn-sm my-1 me-2">Acceptă profilul</a>
                          }
                          {is_admin && item.status != 2 &&
                            <a onClick={() => ToggleProfil(item.id, 2, item.sqlid)} className="btn btn-light-warning btn-sm my-1 me-2">Respinge profilul</a>
                          }
                          {item.status == 1 &&
                            <Link to={"/programare/" + item.sqlid} className="btn btn-light-success btn-sm my-1 me-2">Programare</Link>
                          }
                          {item.status == 2 &&
                            <Link to={"/programare/" + item.sqlid} className="btn btn-light-danger btn-sm my-1 me-2">Profil Respins</Link>
                          }
                          {is_admin &&
                              <a onClick={() => StergereProfilModal(item.id, 1, item.sqlid, 'Ștergere profil ' + item.username, 'Esti sigur ca vrei sa stergi acest profil de kinetoterapeut ?')} className="btn btn-light-danger btn-sm my-1 me-2">Șterge</a>
                          }
                          {!is_logged &&
                              <Link to={"/login/"} className="btn btn-light-danger btn-sm my-1 me-2">Intră în cont pentru a te programa</Link>
                          }
                      </div>
                  </div>
                </div>
            </CSSTransition>
            );
        })}
      </TransitionGroup>
      {/* <StergeKinetoModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalData}
      /> */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalData}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalData.title}
          </Modal.Title>
        </Modal.Header>
          <Modal.Body className="text-center">
              <h3>{modalData.text}</h3>
          </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancelModal} className="btn btn-light-dark btn-sm my-1 me-2">Close</Button>
          <Button onClick={handleSubmitModal} className="btn btn-light-danger btn-sm my-1 me-2">Confirm</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}
  const KinetoterapeutiWrapper = () => {
    const intl = useIntl()
    const { page } = useParams();
    return (
      <>
        <PageTitle breadcrumbs={breadcrumps}>{'Kinetoterapeuti'}</PageTitle>
        <KinetoCard page={page}/>
      </>
    )
  }
  
export {KinetoterapeutiWrapper}