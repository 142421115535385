import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth';
import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileHeader } from '../../modules/profile/ProfileHeader';
import toast from 'react-hot-toast';
import { ProfileSettings } from './partials/Settings';
import { ProfileOverview } from './partials/Overview';
import { ProfileFriends } from './partials/Friends';
import { ProfileComments } from './partials/Comments';
import { Avatars } from './partials/Avatars';

const API_URL = process.env.REACT_APP_API_URL

export const PROFILE_API_URL = `${API_URL}/profile_update/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ProfileWrapper = () => {
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const [friendship, setFriendship] = useState(false)
    const [rating, setRating] = useState(0)
    const [statusUser, setStatusUser] = useState('')
    const [username, setUsername] = useState('')
    const [avatar, setAvatar] = useState('')
    const [AvatarFB, setAvatarFB] = useState('')
    const [isMyProfile, setIsMyProfile] = useState(false)
    const [profileHeader, setProfileHeader] = useState<any[]>([])
    const [profileAbout, setProfileAbout] = useState('')
    const [socials, setSocials] = useState('')
    const navigate = useNavigate();
    const {user_id} = useParams();
    const {currentUser} = useAuth()
    const current_user_id = currentUser?.id
    const user_token = currentUser?.token
    const ProfileAbout = (value) => {
        setProfileAbout(value)
    }
    const Friendship = (value) => {
        setFriendship(value)
    }
    const Rating = (value) => {
        setRating(value)
    }
    const UpdateProfile = () => {
        forceUpdate()
    }
    useEffect(() => {
        axios({
            method: 'GET',
            url: PROFILE_API_URL + user_id,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (response) {
            if(response.data.error){
                navigate('/');
                return toast.error(response.data.error)
            }
            setUsername(response.data.data.username)
            setProfileHeader(response.data.data)
            ProfileAbout(response.data.profile_about)
            setAvatar(response.data.data.avatar)
            setAvatarFB(response.data.avatar_fb)
            if(response.data.data.id === current_user_id){
                setIsMyProfile(true)
            }
            setStatusUser(response.data.status_user)
            Friendship(response.data.Friendship)
            Rating(response.data.rating)
            setSocials(response.data.socials)
        });
    }, [user_id, reducerValue])
    return (
        <>
        <PageTitle breadcrumbs={breadcrumps}>{username + ' Profile'}</PageTitle>
        <ProfileHeader data={profileHeader} statusUser={statusUser} isMyProfile={isMyProfile} friendship={friendship} Friendship={Friendship} Rating={Rating} user_rating={rating} UpdateProfile={UpdateProfile}/>
        <div className="row g-5 g-xl-8">
            <div className='col-xl-6 tab-content'>
                {current_user_id === Number(user_id) &&
                    <ProfileSettings data={profileHeader} UpdateProfile={UpdateProfile} AvatarFB={AvatarFB} socials={socials}/>
                }
                <ProfileOverview username={username} profile_about={profileAbout} isMyProfile={isMyProfile} ProfileAbout={ProfileAbout}/>
                <ProfileFriends data={profileHeader} user_id={user_id} isMyProfile={isMyProfile}/>
                <Avatars UpdateProfile={UpdateProfile} />
            </div>
            <ProfileComments user_id={user_id} username={username} avatar={avatar} isMyProfile={isMyProfile}/>
        </div>
        </>
    )
}
  
export {ProfileWrapper}