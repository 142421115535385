import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../modules/auth";


const API_URL = process.env.REACT_APP_API_URL

export const DONATII_API_URL = `${API_URL}/donatii/get/`

const DonatiiGet = () => {
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const [data, setData] = useState<any[]>([])
    useEffect(() => {
        axios({
            method: 'GET',
            url: DONATII_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(async function (response) {
            setData(response.data.donatii)
        });
    }, []);
    return (
    <React.Fragment>
        <div className="card animate__animated animate__fadeIn" id="kt_pricing">
            <div className="card-body p-lg-17">
                <div className="d-flex flex-column">
                    <div className="mb-13 text-center">
                        <h1 className="fs-2hx fw-bolder mb-5">Plati efectuate de catre utilizatori</h1>
                    </div>
                    <table className="table table-rounded table-row-bordered border gy-7 gs-7">
                    <thead>
                        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                            <th>Nr</th>
                            <th>Description</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Amount</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => {
                            return ( 
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.description}</td>
                                <td>
                                    <div className="symbol symbol-35px symbol-circle mb-5 me-2">
                                        <img src={item.sqlid > 0 ? item.avatar : '/media/avatars/blank.png'} alt="image" />
                                    </div>
                                    <Link to={item.sqlid > 0 ? '/profile/' + item.sqlid : ''}><b>{item.username}</b></Link>
                                </td>
                                <td>{item.email}</td>
                                <td>{item.amount_total} <b>LEI</b></td>
                                <td>{item.date}</td>
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
        <br />
    </React.Fragment>
    )
}

export default DonatiiGet;