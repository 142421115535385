import React, { useEffect, useState, CSSProperties, useCallback, useReducer } from "react";
import { useAuth } from "../../modules/auth";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import axios from "axios";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_URL

export const PROGRAMARI_GET_API_URL = `${API_URL}/programari_update/`
export const PROGRAMARI_TOGGLE_API_URL = `${API_URL}/programari_update/toggle/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Calendar Overview',
        path: '/',
        isSeparator: true,
        isActive: true,
    },
]

function convertDateToISOString(dateString) {
    const date = new Date(dateString);
    return date.toISOString();
}

const StatusEnum = {
    Asteptare: 0,
    Confirmat: 1,
    Anulat: 2,
};

const getThemeMode = () => {
    const defaultThemeMode = 'system';
  
    const name = document.body.getAttribute('data-kt-name') || '';
    const lsKey = `kt_${name ? name + '_' : ''}theme_mode_value`;
    const themeMode = localStorage.getItem(lsKey);
  
    if (!themeMode) {
      if (defaultThemeMode === 'system') {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      } else {
        return defaultThemeMode;
      }
    }
  
    return themeMode;
};
  
export const CalendarKinetot = () => {
    const {currentUser} = useAuth()
    const [dataVersion, setDataVersion] = useState(0);
    const user_token = currentUser?.token
    const current_user_id = currentUser?.id
    const [programari, setProgramari] = useState<any[]>([])
    const [programariKineto, setProgramariKineto] = useState<any[]>([])
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState({ id: null, status: null, content: '', x: 0, y: 0, avatar: '', username: '', sqlid: null, kineto_sqlid: null });

    function getEventColor(status) {
        switch (status) {
          case StatusEnum.Asteptare:
            return 'orange';
          case StatusEnum.Confirmat:
            return '#28a745'; // green
          case StatusEnum.Anulat:
            return '#dc3545'; // red
          default:
            return 'blue';
        }
    }
    
    function setEventToggle(status) {
        switch (status) {
          case StatusEnum.Asteptare:
            return 'Confirma';
          case StatusEnum.Confirmat:
            return 'Anuleaza';
          case StatusEnum.Anulat:
            return 'Confirma';
        }
    }

    function setEvenToggleColor(status) {
        switch (status) {
          case StatusEnum.Asteptare:
            return '#8df58d'; // green
          case StatusEnum.Confirmat:
            return '#dc3545'; // red
          case StatusEnum.Anulat:
            return '#28a745'; // green
        }
    }

    const transformedProgramari = Array.isArray(programari) ?
    programari.map((event) => ({
        id: event.id,
        title: 'Programare la ' + event.kineto_nume + ' - ' + event.status_text,
        start: convertDateToISOString(event.data),
        address: event.address,
        descriere: '',
        telephone: '',
        avatar: event.kineto_avatar,
        status: event.status,
        username: event.kineto_nume,
        sqlid: event.sqlid,
        kineto_sqlid: event.kineto_sqlid,
        backgroundColor: getEventColor(event.status),
    })) : [];
      
    const transformedProgramariKineto = Array.isArray(programariKineto) ?
    programariKineto.map((event) => ({
        id: event.id,
        title: event.username + ' - ' + event.status_text,
        start: convertDateToISOString(event.data),
        address: event.address,
        descriere: event.descriere,
        telephone: event.numar_telefon,
        avatar: event.avatar,
        status: event.status,
        username: event.username,
        sqlid: event.sqlid,
        kineto_sqlid: event.kineto_sqlid,
        backgroundColor: getEventColor(event.status),
    })) : [];

      
    const combinedEvents = [...transformedProgramari, ...transformedProgramariKineto];

    // console.log(combinedEvents)

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
    };      

    const handleEventClick = useCallback((info, eventEl) => {
        const formattedDate = new Intl.DateTimeFormat('en-UK', options).format(new Date(info.event.start));
        const content = [
            `${info.event.title}\n${formattedDate}`,
            `Adresa: ${info.event.extendedProps.address}`,
            info.event.extendedProps.descriere,
            info.event.extendedProps.telephone,
        ].join("\n");
      
        // Get the position of the event element
        const rect = eventEl.getBoundingClientRect();
      
        // Calculate the position relative to the viewport
        const x = rect.left + window.pageXOffset;
        const y = rect.top + window.pageYOffset;
      
        setPopupContent({
          id: info.event.id,
          status: info.event.extendedProps.status,   
          x: x,
          y: y,
          content: content,
          avatar: info.event.extendedProps.avatar,
          username: info.event.extendedProps.username,
          sqlid: info.event.extendedProps.sqlid,
          kineto_sqlid: info.event.extendedProps.kineto_sqlid
        });
        setPopupVisible(true);
        setTimeout(() => forceUpdate());
    }, []);
      
    const closePopup = () => {
        setPopupVisible(false);
    };

    const handleToggle = (id, status, username, sqlid) => {
        let toggle = null;
        switch (status) {
            case StatusEnum.Asteptare:
              toggle = StatusEnum.Confirmat;
              break;
            case StatusEnum.Confirmat:
              toggle = StatusEnum.Anulat;
              break;
            case StatusEnum.Anulat:
              toggle = StatusEnum.Confirmat;
              break;
          }
        if(toggle){
            toggle_programari(toggle, id, username, sqlid)
        }
    };

    const toggle_programari = (toggle, id_db, nume_client, sqlid_client) => {
        axios({
            method: 'POST',
            url: PROGRAMARI_TOGGLE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data : { toggle: toggle, id_db: id_db, nume_client: nume_client,
                sqlid_client: sqlid_client}
        })
        .then(function (r) {
            setDataVersion(dataVersion + 1);
            setPopupVisible(false);
            toast.success(r.data.message)
        });
    }

    const popupStyle: CSSProperties = {
        position: 'absolute',
        left: `${popupContent.x}px`,
        top: `${popupContent.y}px`,
        // Add any other styles you want for the popup.
    };

    useEffect(() => {
        axios({
            method: 'GET',
            url: PROGRAMARI_GET_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (r) {
            setProgramari(r.data.pacient_programari)
            setProgramariKineto(r.data.kineto_sqlid)
        });
    }, [dataVersion])
    return (
        <React.Fragment>
            <div className="card animate__animated animate__fadeIn">
                <div className="card-body p-lg-17">
                    <div className="d-flex flex-column">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bolder mb-5">Calendar Overview</h1>
                        </div>
                    </div>
                    <FullCalendar
                        initialView="dayGridMonth"
                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                        events={combinedEvents}
                        eventClick={(info) => handleEventClick(info, info.el)}
                        timeZone="UTC"
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        }}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                        }}
                    />
                </div>
            </div>
            {popupVisible && (
            <div className={`event-popup${getThemeMode() === 'dark' ? '-dark' : ''}`} style={popupStyle}>
                <div className="symbol symbol-65px symbol-circle mb-5 d-flex flex-center">
                    <img src={popupContent.avatar} alt="avatar" />
                </div>
                <pre className={`event-popup-content${getThemeMode() === 'dark' ? '-dark' : ''}`}>
                {popupContent.content}
                </pre>
                <div className="event-popup-buttons">
                    <button className={`event-popup-close${getThemeMode() === 'dark' ? '-dark' : ''}`} onClick={closePopup}>
                        Close
                    </button>
                    {((popupContent.kineto_sqlid === current_user_id) || (popupContent.kineto_sqlid !== current_user_id && popupContent.status === StatusEnum.Asteptare)) && (
                    <button
                        className={`event-popup-toggle${getThemeMode() === 'dark' ? '-dark' : ''}`}
                        onClick={() => {
                            const newStatus = (popupContent.kineto_sqlid === current_user_id) ? popupContent.status : StatusEnum.Confirmat;
                            handleToggle(popupContent.id, newStatus, popupContent.username, popupContent.sqlid);
                        }}
                        style={{
                            backgroundColor: setEvenToggleColor((popupContent.kineto_sqlid === current_user_id) ? popupContent.status : StatusEnum.Confirmat)
                        }}
                    >
                        {setEventToggle((popupContent.kineto_sqlid === current_user_id) ? popupContent.status : StatusEnum.Confirmat)}
                    </button>
                )}
                </div>
            </div>
            )}
            <PageTitle breadcrumbs={breadcrumps}>{'Calendar Overview'}</PageTitle>
        </React.Fragment>
    )
}
  
  