import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import { modulesQuill } from '../../../editorquill/modulesQuill';
import { useAuth } from '../../../modules/auth';

const API_URL = process.env.REACT_APP_API_URL

export const ACTUALIZARI_UPDATE_API_URL = `${API_URL}/editupdate/`

export const EditUpdate = (props) => {
    const [data, setData] = useState({title: ''})
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const [valueQuill, setValueQuill] = useState('')
    useEffect(() => {
        setValueQuill(props.item?.content)
        if(props.item?.title){
            setData({title: props.item?.title})
        }
    }, [props.item])
    const handleSubmitModal = () => {
        if(valueQuill == props.item?.content){
            return toast.error('Nu ai modificat nimic din continut!')
        }
        axios({
            method: 'POST',
            url: ACTUALIZARI_UPDATE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data: {id_update: props.item.id, content: valueQuill, title: data.title}
        })
        .then(function (r) {
            props.item.title = data.title
            props.item.content = valueQuill
            toast.success(r.data.message)
            props.toggle()
        });
    }
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}
            onHide={props.toggle}
            >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.item?.title}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
            <div className="mb-10">
            <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">Titlul</label>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-lg-8 fv-row fv-plugins-icon-container">
                            <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={data.title} onChange={(e) => setData({title: e.target.value})}/>
                        </div>
                    </div>
                </div>
            </div>
                <ReactQuill theme="snow" value={valueQuill ? valueQuill : props.item?.content} onChange={setValueQuill} modules={modulesQuill}/>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.toggle} className="btn btn-light-dark btn-sm my-1 me-2">Close</Button>
                <Button onClick={() => handleSubmitModal()} className="btn btn-light-warning btn-sm my-1 me-2">Edit</Button>
            </Modal.Footer>
        </Modal>
    )
}