/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import {Link} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import {isNotEmpty, KTSVG} from '../../../helpers'

const API_URL = process.env.REACT_APP_API_URL

export const NOTIFICATIONS_API_URL = `${API_URL}/updatenotifications/0`
export const CLEAR_NOTIFICATION_API_URL = `${API_URL}/clearnotify/`

const HelpDrawer = () => {
  const {currentUser, logout} = useAuth()
  const [data, setData] = useState<any[]>([])
  const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
  const user_token = currentUser?.token
  const is_admin = currentUser?.admin
  /* 
    Get all notification
  */
  useEffect(() => {
      axios({
          method: 'POST',
          url: NOTIFICATIONS_API_URL,
          headers: {'Authorization': `${user_token}`}
      })
      .then(function (response) {
          let data = response.data
          setData(data.notif_data)
      });
  }, [reducerValue])
  /* 
    Read notification if he clicks the link
  */
  function ReadNotif(id_notif: any, readed: any){
    if(readed < 1){
      axios({
        method: 'POST',
        url: CLEAR_NOTIFICATION_API_URL,
        data: {'id_notif': id_notif},
        headers: {'Authorization': `${user_token}`}
      }).then(function (response) {
        forceUpdate()
      });
    }
  }
  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'405px', 'md': '405px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100 my-6'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <div className="card-title">
            <div className="symbol symbol-100 flex-column me-3">
              <img alt='Logo' src={currentUser?.avatar_url} />
              <i className="symbol-badge bg-success"></i>
            </div> 
            <div className="d-flex justify-content-center flex-column me-3">
              <a href="#" className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1" id="[[nickname]]">Profilul tau, {currentUser?.username}!</a> 
              <div className="mb-0 lh-1">
                <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                <span className="fs-7 fw-bold text-muted">Active</span>
                &nbsp;
                &nbsp;
                <button onClick={logout} className="btn btn-sm btn-light-danger font-weight-bolder py-2 px-5">Ieși din cont</button>
              </div>
            </div>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body' id='kt_help_body'>
          {/* begin::Content */}
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-primary svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"/>
                      <path d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z" fill="currentColor"/>
                      <path d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z" fill="currentColor"/>
                      </svg>
                    </span>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                <div className="d-flex flex-column">
                  <Link to={'/profile/' + currentUser?.id} className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Profilul meu</Link>
                  <div className="fs-6 fw-bold text-muted">Setările contului și altele</div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                      <span className="svg-icon svg-icon-primary svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="currentColor"/>
												<path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="currentColor"/>
												</svg>
											</span>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                <div className="d-flex flex-column">
                  <Link to={'/notifications/'} className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Notificările mele</Link>
                  <div className="fs-6 fw-bold text-muted">Cele mai noi notificări</div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>

            <div className="d-flex flex-stack">
              <div className="d-flex">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                      <span className="svg-icon svg-icon-primary svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"/>
												<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"/>
												</svg>
											</span>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                <div className="d-flex flex-column">
                  <Link to={'/programari/'} className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Programările mele</Link>
                  <div className="fs-6 fw-bold text-muted">Vezi ce programari ai primit</div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>

            <div className="d-flex flex-stack">
              <div className="d-flex">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                      <span className="svg-icon svg-icon-primary svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"/>
												<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"/>
												</svg>
											</span>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                <div className="d-flex flex-column">
                  <Link to={'/kinetoterapeuti/calendar'} className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Calendar</Link>
                  <div className="fs-6 fw-bold text-muted">Vezi ce programari ai in calendar</div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-primary svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"/>
                      <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"/>
                      </svg>
                    </span>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                <div className="d-flex flex-column">
                  <Link to={'/messages/'} className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Mesajele mele private</Link>
                  <div className="fs-6 fw-bold text-muted">Vezi ce mesaje ai primit</div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"/>
                      <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"/>
                      <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"/>
                      </svg>
                    </span>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                <div className="d-flex flex-column">
                  <Link to={'/plati/'} className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Plățile mele</Link>
                  <div className="fs-6 fw-bold text-muted">Vezi ce plăți ai efectuat</div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>
            <div className="d-flex flex-stack">
              <div className="d-flex">
                { data.length > 0 &&
                  <h5 className="mb-5">Notificări recente</h5>
                }
                { data.length < 1 &&
                  <h5 className="mb-5">Nu ai notificări recente!</h5>
                }
              </div>
            </div>
            {data.map((item, index) => {
            return (
              <div key={item.id} className={item.readed ? 'd-flex align-items-center bg-light-success rounded p-5 mb-7' : 'd-flex align-items-center bg-light-warning rounded p-5 mb-7'}>
                <div className="me-4 flex-shrink-0 text-lef w-40px">
                  <img width="45" height="45" src={item.icon}/>
                </div>
                <div className="flex-grow-1 me-2">
                  <Link onClick={() => ReadNotif(item.id, item.readed)} to={item.link} id={item.id} className={'fw-bolder text-gray-800 text-hover-primary fs-6 ' + item.class} dangerouslySetInnerHTML={{__html: item.info}}></Link>
                  <span className="text-muted fw-bold d-block">{item.date}</span>
                </div>
              </div>
            );
            })}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {HelpDrawer}
