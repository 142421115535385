import axios from "axios";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_URL

export const UNLINK_SOCIAL_API_URL = `${API_URL}/unlink_social/`

export enum SocialEnum {
  Default = 0,
  Uploaded = 1,
  Facebook = 2,
  Google = 3
}

export const UnLinkSocial = (user_token, social) => {
  return axios({
      method: 'POST',
      url: UNLINK_SOCIAL_API_URL,
      headers: {'Authorization': `${user_token}`},
      data: {social: social}
  })
  .then(function (r) {
      toast.success(r.data.message);
  });
}