/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  bgImage?: string
}
const KinetoInfo = ({
  bgImage = toAbsoluteUrl('/media/svg/humans/custom-2.svg'),
}: Props) => {
  return (
    <div className="card card-flush h-lg-100">
        <div className="card-body d-flex p-0">
            <div className="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center" style={{backgroundColor: `#FFF4DE`, backgroundPosition: `left bottom`, backgroundSize: `auto 100%`, backgroundImage: `url("${bgImage}")`}}>
                <div className="row">
                    <div className="col-12 col-xl-5"></div>
                    <div className="col-12 col-xl-7">
                        <h4 className="text-danger font-weight-bolder">Vezi kinetoterapeuții activi</h4> 
                        <p className="font-weight-bold text-muted font-size-lg">Pentru a vizualiza profilul fiecarui kinetoterapeut, puteti accesa butonul de mai jos.</p>
                        <a href="/kinetoterapeuti/" className="btn btn-danger font-weight-bold py-2 px-6">Accesează-mă</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export {KinetoInfo}
