import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const FORUM_VIEW_API_URL = `${API_URL}/afiseaza_stire/`
    
const FetchViewForum = async (token, id_forum) => {
    const url = FORUM_VIEW_API_URL + id_forum
    const { data } = await axios.get(url, {headers: {'Authorization' : token}})
    return data
}
    
export default FetchViewForum;