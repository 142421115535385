import axios from "axios"
import React, { useEffect, useReducer, useState } from "react"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { useAuth } from "../../modules/auth"
import { useJsApiLoader, GoogleMap, Autocomplete, MarkerF } from '@react-google-maps/api'
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { PropagateLoader } from "react-spinners"

const API_URL = process.env.REACT_APP_API_URL

export const PROGRAMARI_GET_API_URL = `${API_URL}/programari_update/`
export const PROGRAMARI_TOGGLE_API_URL = `${API_URL}/programari_update/toggle/`

const breadcrumps: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ProgramariView = () => {
    const [isLoading, setIsLoading] = useState(true)
    const {currentUser} = useAuth()
    const user_token = currentUser?.token
    const current_user_id = currentUser?.id
    const [reducerValue, forceUpdate] = useReducer(x=> x + 1, 0);
    const [kineto_sqlid, setKineto_sqlid] = useState<any[]>([])
    const [pacient_programari, setPacient_programari] = useState<any[]>([])
    const [rows, setRows] = useState(0)
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAhDjeTg2bDGMcUsVG6bCukMKHtw95kaQs',
        libraries: ['places'],
    })
    useEffect(() => {
        axios({
            method: 'GET',
            url: PROGRAMARI_GET_API_URL,
            headers: {'Authorization': `${user_token}`}
        })
        .then(function (r) {
            setKineto_sqlid(r.data.kineto_sqlid)
            setPacient_programari(r.data.pacient_programari)
            setRows(r.data.rows)
            setTimeout(function() {
                setIsLoading(false)
            }, 1500);
        });
    }, [reducerValue])
    const toggle_programari = (toggle, id_db, nume_client, sqlid_client) => {
        axios({
            method: 'POST',
            url: PROGRAMARI_TOGGLE_API_URL,
            headers: {'Authorization': `${user_token}`},
            data : { toggle: toggle, id_db: id_db, nume_client: nume_client,
                sqlid_client: sqlid_client}
        })
        .then(function (r) {
            forceUpdate()
            toast.success(r.data.message)
        });
    }
    return(
        <React.Fragment>
        <PageTitle breadcrumbs={breadcrumps}>{'Programari'}</PageTitle>
        {isLoading &&
        <div className="d-flex justify-content-center">
            <PropagateLoader loading={true} color="#36d7b7" />
        </div>
        }
        {isLoading &&
        <br />
        }
        <div className="row">
            <div className="col-xl-8" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <div className="card card-custom card-stretch gutter-b">
                    <div className="card-header border-0 pt-6 mb-2">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">Programările mele la Kinetoterapeuti</span>
                            <span className="text-muted font-weight-bold font-size-sm">În total <b>{rows}</b> programări</span>
                        </h3>
                    </div>
                    <div className="card-body pt-2">
                        {!pacient_programari &&
                        <div className="text-center font-weight-boldest"><br /><br /><br /> <b>Nu ai programări active!</b></div>
                        }
                        <div className="table-responsive">
                            <table className="table table-borderless mb-0">
                                <tbody>
                                    {pacient_programari && pacient_programari.map((item, i) => {
                                    return (
                                    <tr key={i}>
                                        <td className="w-40px align-middle pb-6 pl-0 pr-2">
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-40 mr-3">
                                                    <img alt="Pic" src={item.kineto_avatar}/>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="font-size-lg font-weight-bolder text-dark-75 align-middle w-150px pb-6"><Link to={'/profile/' + item.kineto_sqlid} className="text-gray-800 text-hover-primary font-size-h4 font-weight-bolder mb-1">{item.kineto_nume}</Link></td>
                                        <td className="font-weight-bold text-muted align-middle pb-6"><span className={'btn btn-light-' + item.status_icon + ' btn-sm my-1 me-2'}>{item.status_text}</span></td>
                                        <td className="font-weight-bold text-muted align-middle pb-6"><span className="btn btn-light-primary btn-sm my-1 me-2">{item.data}</span></td>
                                        <td className="font-weight-bold text-muted align-middle pb-6"><span className="btn btn-light-primary btn-sm my-1 me-2">{item.address}</span></td>
                                    </tr>
                                    );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
        {kineto_sqlid &&
        <div className="card animate__animated animate__fadeIn">
            <div className="card-header border-0 pt-6" style={{justifyContent: 'center'}}>
                <div className="card-title">
                    <span className="svg-icon svg-icon-success svg-icon-2x me-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M16.163 17.55C17.0515 16.6633 17.6785 15.5488 17.975 14.329C18.2389 13.1884 18.8119 12.1425 19.631 11.306L12.694 4.36902C11.8574 5.18796 10.8115 5.76088 9.67099 6.02502C8.15617 6.3947 6.81277 7.27001 5.86261 8.50635C4.91245 9.74268 4.41238 11.266 4.44501 12.825C4.46196 13.6211 4.31769 14.4125 4.0209 15.1515C3.72412 15.8905 3.28092 16.5617 2.71799 17.125L2.28699 17.556C2.10306 17.7402 1.99976 17.9897 1.99976 18.25C1.99976 18.5103 2.10306 18.7598 2.28699 18.944L5.06201 21.719C5.24614 21.9029 5.49575 22.0062 5.75601 22.0062C6.01627 22.0062 6.26588 21.9029 6.45001 21.719L6.88101 21.288C7.44427 20.725 8.11556 20.2819 8.85452 19.9851C9.59349 19.6883 10.3848 19.5441 11.181 19.561C12.1042 19.58 13.0217 19.4114 13.878 19.0658C14.7343 18.7201 15.5116 18.2046 16.163 17.55Z" fill="currentColor"/>
                        <path d="M19.631 11.306L12.694 4.36902L14.775 2.28699C14.9591 2.10306 15.2087 1.99976 15.469 1.99976C15.7293 1.99976 15.9789 2.10306 16.163 2.28699L21.713 7.83704C21.8969 8.02117 22.0002 8.27075 22.0002 8.53101C22.0002 8.79126 21.8969 9.04085 21.713 9.22498L19.631 11.306ZM13.041 10.959C12.6427 10.5604 12.1194 10.3112 11.5589 10.2532C10.9985 10.1952 10.4352 10.332 9.96375 10.6405C9.4923 10.949 9.14148 11.4105 8.97034 11.9473C8.79919 12.4841 8.81813 13.0635 9.02399 13.588L2.98099 19.631L4.36899 21.019L10.412 14.975C10.9364 15.1816 11.5161 15.2011 12.0533 15.0303C12.5904 14.8594 13.0523 14.5086 13.361 14.037C13.6697 13.5654 13.8065 13.0018 13.7482 12.4412C13.6899 11.8805 13.4401 11.357 13.041 10.959Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <div className="alert-text text-center"><b>Gestioneaza programarile pacientilor mai jos</b></div>
                </div>
            </div>
        </div>
        }
        {kineto_sqlid &&
        <br />
        }
        {kineto_sqlid &&
        <br />
        }
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
            {kineto_sqlid && kineto_sqlid.map((item, i) => {
            return (
            <div key={i} className="col-xl-4">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px me-5">
                                <img src={item.avatar} alt="image" />
                            </div>
                            <div className="d-flex flex-column mr-auto">
                                <div className="d-flex flex-column mr-auto">
                                    <Link to={'/profile/' + item.sqlid} className="text-gray-800 text-hover-primary font-size-h4 font-weight-bolder mb-1">{item.username}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="mb-10 mt-5 font-weight-bold">{item.descriere}</div>
                        <div className="d-flex mb-5">
                            <div className="d-flex align-items-center mr-7">
                                <span className="font-weight-bold mr-4">Status</span>
                                &nbsp; 
                                &nbsp; 
                                <span id={'status_' + item.id} className={'btn btn-light-' + item.status_icon + ' btn-sm my-1 me-2'}>{item.status_text}</span>
                            </div>
                        </div>
                        <div className="d-flex mb-5">
                            <div className="d-flex align-items-center mr-7">
                                <span className="font-weight-bold mr-4">Programat</span>
                                &nbsp; 
                                &nbsp; 
                                <span className="btn btn-light-primary btn-sm my-1 me-2">{item.data}</span>
                            </div>
                        </div>
                        <div className="d-flex mb-5 align-items-center">
                            <span className="d-block font-weight-bold mr-5">Contact</span>
                            &nbsp; 
                            &nbsp; 
                            <div className="d-flex flex-row-fluid align-items-center">
                                <a href={'tel:+4' + item.numar_telefon} className="btn btn-light-success btn-sm my-1 me-2">{item.numar_telefon}</a>
                            </div>
                        </div>
                        <div className="d-flex mb-5 align-items-center">
                            <span className="d-block font-weight-bold mr-5">Adresa</span>
                            &nbsp; 
                            &nbsp; 
                            <div className="d-flex flex-row-fluid align-items-center">
                                <span className="btn btn-light-warning btn-sm my-1 me-2">{item.place} {item.address}</span>
                            </div>
                        </div>
                        <div className="d-flex mb-5 align-items-center">
                            <span className="d-block font-weight-bold mr-5">Locație</span>
                            &nbsp; 
                            &nbsp; 
                            <div className="d-flex flex-row-fluid align-items-center">
                                <div style={{width: '410px', height: '170px'}}>
                                    { isLoaded &&
                                    <GoogleMap
                                        center={{lat: item.MapLat, lng: item.MapLng}}
                                        zoom={12}
                                        mapContainerStyle={{ width: '100%', height: '100%' }}
                                        options={{
                                            zoomControl: false,
                                            streetViewControl: false,
                                            mapTypeControl: false,
                                            fullscreenControl: false,
                                        }}
                                        >
                                        <MarkerF position={{lat: item.MapLat, lng: item.MapLng}}/>
                                    </GoogleMap>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex align-items-center flex-wrap pt-0" style={{justifyContent: 'center'}}>
                        <div className="d-flex">
                            {item.status != 2 &&
                            <div className="d-flex align-items-center mr-7">
                                <a id={'anuleaza_' + item.id} onClick={() => toggle_programari(2, item.id, item.username, item.sqlid)} className="btn btn-light-danger btn-sm my-5 me-2">Anulează</a>
                            </div>
                            }
                            <br />
                            {item.status != 1 &&
                            <div className="d-flex align-items-center mr-7">
                                <a id={'confirma_' + item.id} onClick={() => toggle_programari(1, item.id, item.username, item.sqlid)} className="btn btn-light-success btn-sm my-5 me-2">Confirma</a>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            );
            })}
        </div>
        </React.Fragment>
    )
}

export { ProgramariView }